<template>
  <component
    @click="select"
    v-pm-segment
    v-show="!mergedProps.isHidden"
    :is="segmentTag('button')"
    class="button select-none cursor-pointer align-top"
    :class="finalClassList"
    :type="segmentTag('button') == 'button' ? type : undefined"
    v-bind="{
      'x-on:click': onClickAction,
      ...attrList,
      ...tracking,
    }"
    :style="style"
  >
    <pm-icon
      class="button__icon"
      v-bind="childSegment('icon')"
      :locked-groups="['link']"
    />
    <pm-text
      tag="span"
      class="button__label"
      v-bind="childSegment('label')"
      :locked-groups="['link']"
    />
  </component>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-button",
  mixins: [segment],
  props,

  computed: {
    onClickAction() {
      switch (this.mergedProps.onClick) {
        case "integration":
          return `$store.integration(${this.mergedProps.integrationId}).run()`;

        case "open_modal":
          return `$store.modal.open('promo-modal')`;

        case "close_modal":
          return `$store.modal.close('promo-modal')`;

        case "product_page":
        case "":
        default:
          return this.mergedProps["xOn:click"];
      }
    },

    tracking() {
      if (this.mergedProps.onClick == "product_page") {
        return {
          "data-track-content": "",
          "data-content-name": "Product Page",
        };
      } else {
        return { "data-track-content": "" };
      }
    },
  },
};
</script>
