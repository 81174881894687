import "./../src/styles/global.scss";

const install = (
  Vue,
  options = {
    bus: {},
    mode: "editor",
    apiResolver(id, options) {
      console.warn(id);
      console.warn("API Resolver is not set!");
    },
  }
) => {
  const BUS = options.bus;
  const IS_DESIGNER = options.mode == "designer";
  const IS_EDITOR = options.mode == "editor";
  const IS_RENDERER = options.mode == "renderer";
  const MODE = options.mode;
  
  /**==============================================
   * * COMPONENTS
   * Register all segments as the Vue Components
   *=============================================**/
  const segments = import.meta.glob("./components/*/Component.vue", {
    import: "default",
    eager: true,
  });

  Object.entries(segments).forEach(([path, segment]) => {
    const segmentName = path.split("/")[2];
    Vue.component(segmentName, segment);
  });

  /**==============================================
   * * MIXIN
   * Global mixin to provide plugin options.
   * Can this be handled in better way?
   *=============================================**/
  Vue.mixin({
    data() {
      return {
        BUS,
        MODE,
        IS_DESIGNER,
        IS_EDITOR,
        IS_RENDERER,
      };
    },
  });

  /**==============================================
   * * PROTOTYPES
   *=============================================**/
  Vue.prototype.$api = options.apiResolver;

  /**==============================================
   * * DIRECTIVES
   * This directive tracks the user's cursor movement
   * and triggers an event on segment hover.
   *=============================================**/
  Vue.directive("pm-segment", {
    bind(el, binding, vnode) {
      // Designer does not need this segment for now.
      if (IS_DESIGNER) return;

      el.addEventListener("mouseover", (e) => {
        const { ref, refIndex } = vnode.elm.dataset;
        const moduleUuid = vnode.elm.closest("[data-uuid]").dataset.uuid;
        e.stopPropagation();
        BUS.$emit("segment-hover", {
          segmentName: ref,
          segmentIndex: refIndex,
          moduleUuid,
        });
      });
      el.addEventListener("mouseout", (e) => {
        e.stopPropagation();
        BUS.$emit("segment-hover");
      });
    },
  });
};

const plugin = {
  install,
  version: "__VERSION__",
};

export default plugin;

if (typeof window !== "undefined" && window.Vue) {
  window.Vue.use(plugin);
}
