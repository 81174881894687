<template>
  <component
    v-show="!mergedProps.isHidden"
    class="select"
    :class="finalClassList"
    v-bind="attrList"
    :style="style"
    @click="select"
    v-pm-segment
    :is="segmentTag('div')"
  >
    <select :name="mergedProps.name" :data-pm-variable="getVariable('options')">
      <option value="">{{ mergedProps.placeholder || "Select" }}</option>
      <option
        v-for="(option, index) in mergedProps.options"
        :key="`option-${option.value}-${index}`"
        :value="replaceVariableValue(option.value)"
        :data-pm-variable="getVariableName(option.value)"
      >
        {{ replaceVariableValue(option.label) }}
      </option>
    </select>
  </component>
</template>

<script>
import props from "./props";
import segment from "../../mixins/segment";
import { replace } from "lodash-es";

export default {
  name: "pm-select",
  props,
  mixins: [segment],

  inject: {
    VARIABLES: {
      default: () => () => {},
    },
  },

  computed: {
    variables() {
      return this.VARIABLES();
    },
  },

  methods: {
    getVariableName(value) {
      if (this.isVariableString(value)) {
        return value
      } else {
        return undefined;
      }
    },
  },
};
</script>

