<template>
  <component
    :is="segmentTag('div')"
    class="text"
    :class="[...scl_text, { 'text--variable': isContentVariable }]"
    ref="input"
    v-show="!mergedProps.isHidden"
    v-bind="attrList"
    :style="style"
    @click="onSelect"
    @focus="onSelect"
    v-pm-segment
    :title="
      isContentVariable
        ? 'Can not edit as the Content is set to variable. Change it to \'Custom Text\' to edit.'
        : ''
    "
    :key="`editor-state--${isEditorInitialized}`"
  >
    <div v-if="isEditorInitialized && isEditable">
      <BubbleMenu
        :editor="editor"
        :plugin-key="MODULE_UUID + '--' + segmentName"
        :tippy-options="tippyOptions()"
      >
        <!-- Expecting This Component to be available on Pagemaker Editor -->
        <PmTextInlineEditor :editor="editor" ref="inlineEditor" />
      </BubbleMenu>
      <EditorContent
        class="text__editor pm--unwrap"
        onkeyup="event.preventDefault()"
        :editor="editor"
      />
    </div>
    <div
      v-else
      class="text__wrapper"
      :data-pm-variable="variableName"
      v-html="editorContent"
    ></div>
  </component>
</template>

<script>
/**
 * on <editor-content> event.preventDefault is required
 * When text is used as child of button, it triggers click event of button.
 */
import props from "./props";
import TailwindMark from "./TailwindMark";
import Link from "@tiptap/extension-link";
import segment from "../../mixins/segment";
import { BubbleMenu } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import { debounce, replace } from "lodash-es";
import Underline from "@tiptap/extension-underline";
import { Editor, EditorContent } from "@tiptap/vue-2";

export default {
  name: "pm-text",
  mixins: [segment],
  props,

  data() {
    return {
      editor: null,
      cursorPosition: null,
      editing: false,
      isEditorInitialized: false,
      isTiptapEditing: false,
    };
  },

  /**==============================================
   * * INFO
   * Pagemaker Editor does the injection.
   * Injections here require default values because:
   * The Pagemaker Designer does not inject
   * and hence it should not throw error.
   *=============================================**/

  components: {
    EditorContent,
    BubbleMenu,
  },

  watch: {
    /**
     * NOTE:
     * We should only set content when the whole string needs to be replaced.
     * For example variable change or set event
     * In normal cases, updating the content will place the editing cursor at the end of line.
     *
     * The above comment doesn't apply as
     * We've handled the caret positioning with the tiptap API now.
     */
    "mergedProps.content"(newValue, oldValue) {
      if (this.editing) return;
      if (!this.editor) return;
      if(!this.isTiptapEditing) return;

      try {
        const content = this.editorContent;
        this.editor.commands.setContent(content);
        this.editor.setEditable(!this.isContentVariable);

        if (!this.isContentVariable && this.cursorPosition) {
          this.editor
            .chain()
            .focus()
            .setTextSelection(this.cursorPosition)
            .run();
        }
      } catch (err) {
        console.error(err);
        this.$sentry.catch(err);
      }
    },
    "mergedProps.variable"(newValue, oldValue) {
      try {
        if(this.editor) {
          const content = this.editorContent;
          this.editor.commands.setContent(content);
        }
      }catch (err) {
        console.error(err);
        this.$sentry.catch(err);
      }
    },
    "mergedProps.tag"() {
      this.editor.chain().selectAll().setHeading({ level: 1 }).run();
    },
  },

  mounted() {
    //this.editor =
    this.BUS.$on("tiptap-destroy", (payload) => {
      this.onDeselect(payload);
    });
  },

  beforeDestroy() {
    this.destroyTiptap();
  },

  computed: {
    // disableContentEditing = the prop for this pm-text segment
    isEditable() {
      return !(
        this.disableContentEditing ||
        this.isContentVariable ||
        this.IS_DESIGNER
      );
    },

    tiptapEditable() {
      return !(
        this.disableContentEditing ||
        this.IS_DESIGNER
      );
    },
    isContentVariable() {
      return this.contentSwitching && this.mergedProps.variable;
    },

    scl_text() {
      const classes = [
        "whitespace-pre-line",
        "break-normal",
        ...this.finalClassList,
      ];

      if (this.mergedProps.fontStyle) {
        classes.push(this.mergedProps.fontStyle);
      }
      if (this.mergedProps.transform) {
        classes.push(this.mergedProps.transform);
      }
      if (this.mergedProps.decoration) {
        classes.push(this.mergedProps.decoration);
      }

      classes.push(...this.variantClassList("leading", "leading"));
      classes.push(...this.variantClassList("text", "size"));
      classes.push(...this.variantClassList("font", "weight"));
      classes.push(...this.variantClassList("font", "family"));

      return classes;
    },

    editorContent() {
      if(this.mergedProps.variable ) {
        return this.replaceVariableValue(this.mergedProps.content)
      }
      // else if (this.editor) {
      //   return this.mergedProps.content;
      // } 
      else {
        return this.mergedProps.content;
      }
    },
    variableName() {
      return this.mergedProps.variable && this.mergedProps.content;
    }
  },

  methods: {
    onSelect(e) {
      const { ref, refIndex } = this.$vnode.elm.dataset;
      this.BUS.$emit("tiptap-destroy", {
        ref,
        refIndex,
      });
      if (!this.isEditorInitialized) {
        this.initTiptap(e);
      } else {
        this.select(e);
      }
    },

    initTiptap(e) {
      try {
        this.editor = new Editor({
          extensions: [
            StarterKit,
            Underline,
            TailwindMark,
            Link.configure({
              openOnClick: false,
            }),
          ],
          content: this.editorContent, // If editor was already initialised, get the content from it.
          editable: this.tiptapEditable,
          autofocus: "all",
          onUpdate: () => {
            this.editing = true;
            this.input(this.editor.getHTML());
          },
          onBlur: () => {
            this.isTiptapEditing = false;
          },
          onFocus: () => {
            this.isTiptapEditing = true;
          },
          onCreate: () => {
            this.isEditorInitialized = true;
            this.select(e);
          },
          onDestroy: () => {
            this.isEditorInitialized = false;
          },

          /**
           * When selection updates, we need to tell inlineEditor
           * Which is available on Pagemaker Editor
           */
          onSelectionUpdate: ({ editor }) => {
            if (this.isEditable) {
              this.$refs.inlineEditor?.onSelectionUpdate(editor);
            }
          },
        });
      } catch (err) {
        console.error(err);
        this.$sentry.catch(err);
      }
    },

    destroyTiptap() {
      try {
        this.editor?.destroy();
      } catch (err) {
        console.error(err);
        this.$sentry.catch(err);
      }
    },

    onDeselect(current) {
      const { ref, refIndex } = this.$vnode.elm.dataset;
      const { ref: activeRef, refIndex: activeRefIndex } = current;

      if (ref !== activeRef) {
        if (activeRefIndex && refIndex != activeRefIndex) {
          this.destroyTiptap();
        } else {
          this.destroyTiptap();
        }
      }
    },

    /**
     * Once user starts inputting the content, we should stop watching and listening
     * to mergedProps.content value because we're triggering the changes from here only.
     * This will also help to preserve the caret position.
     */
    input: debounce(function (content) {
      this.BUS.$emit("segment-content", content);
      this.editing = false;
      this.cursorPosition = this.editor.state.selection;
    }, 500),

    /**
     * Tippy is creating issue because it is appended inside iframe created by vue component for scoping
     * Hence tippy is appended to parent page container hence need to do the settings here.
     */
    tippyOptions() {
      return {
        appendTo: () => document.body,
        /**
         * offset should be function here to allow
         * positioning of an element based on breakpoint & window size.
         */
        offset: () => {
          const left =
            document.querySelector("#editor-iframe").offsetLeft + 200;
          return [left, 50];
        },
        maxWidth: "400px",
        placement: "bottom",
      };
    },
  },
};
</script>

<style>
/* 
  Bubble Menu is not visible in the editor.
  When we select text, update any style, and then select text again bubble menu want work.
  This occurs because the meargeProps get updated and the component is re-rendered.
*/
.tippy-content > div {
  visibility: visible !important;
}
</style>