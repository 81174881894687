import props from "./../../../src-utils/propDefiner";

export default {
  ...props("onClick", { type: String }, "pm-button"),
  ...props("xOn:click", { type: String }, "pm-button"),
  ...props("type", { type: String, default: "button" }, "pm-button"),

  //Common Props Override
  ...props("display", { type: String, default: "inline-flex" }),
  ...props("items", { type: String, default: "center" }),
  ...props("justify", { type: String, default: "center" }),
};
