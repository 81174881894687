<template>
  <!-- 
    Remove modalCurrent in future
  -->
  <div
    v-show="!mergedProps.isHidden"
    :id="`modal-${mergedProps.name}`"
    class="modal fixed inset-0 z-50 overflow-auto"
    :class="finalClassList"
    v-bind="attrList"
    :style="style"
    @click="select"
    :x-bind:class="`{ hidden: !(modalCurrent =='${mergedProps.name}' || $store.modal.current =='${mergedProps.name}')}`"
    v-pm-segment
  >
    <pm-wrapper v-bind="childSegment('wrapper')">
      <pm-wrapper v-bind="childSegment('header')">
        <pm-text v-bind="childSegment('title')" />
        <pm-button v-bind="childSegment('close')" />
      </pm-wrapper>

      <slot />
    </pm-wrapper>

    <!-- MODAL NOTE -->
    <div class="pm--ignore mt-2">
      <div class="text-xs">
        <p>This is a modal. To open this modal:</p>
        <ul class="list-decimal pl-5">
          <li>Select any Button.</li>
          <li>Change property "On Click" as "Open Coupon Redemption Modal"</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-modal",
  props,
  mixins: [segment],
};
</script>
