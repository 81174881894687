import props from "./../../../src-utils/propDefiner";

export default {
  ...props("expandFrom", { type: String, default: "lg" }, "pm-menu"), //Show complete menu after
  ...props("itemsPosition", { type: String, default: "left" }, "pm-menu"), //Show complete menu after

  child: {
    type: Object,
    default: () => ({
      items: {
        bg: "white",
        count: 4,
        display: "flex",
        direction: "col",
        flexGrow: 0,
        w: 64,
        "lg:direction": "row",
        "lg:w": "auto",
      },

      item: {
        px: "5",
        py: "3",
        justify: "start",
        borderB: "DEFAULT",
        borderColor: "gray-100",
        child: {
          label: {
            weight: "bold",
            size: "sm",
            content: "Link",
          },
        },
        propsByIndex: [
          { child: { label: { content: "Home" } } },
          { child: { label: { content: "Features" } } },
          { child: { label: { content: "Testimonials" } } },
          { child: { label: { content: "Gallery" } } },
          { child: { label: { content: "FAQs" } } },
        ],
        "lg:borderB": 2,
        "lg:borderColor": "transparent",
        "lg:hover:borderColor": "primary-500",
      },

      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bg: "black",
        bgOpacity: 70,
      },

      toggle: {
        bg: "gray-100",
        rounded: "full",
        h: 12,
        w: 12,
        child: {
          icon: { name: "mdi:menu", w: 5, h: 5 },
        },
      },

      close: {
        position: "fixed",
        bottom: 4,
        right: 4,
        bg: "white",
        rounded: "full",
        p: 3,
        zIndex: 50,
        h: 12,
        w: 12,
        child: {
          icon: { name: "mdi:close", w: 5, h: 5 },
          label: { isHidden: true },
        },
      },
    }),
  },
};
