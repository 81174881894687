import border from "./../../src-config/border-props";
import inset from "./../../src-config/inset-props";
import rounded from "./../../src-config/rounded-props";
import spacers from "./../../src-config/spacer-props";

export default {
  computed: {
    /**
     * When segment is hidden, it is not removed from DOM to avoid re-rendering.
     * Removing from DOM gives the performance issues.
     * Hence it is hidden with v-show and pm--ignore class is given to ignore it while saving.
     */
    cl_isHidden() {
      return this.mergedProps.isHidden ? ["pm--ignore"] : [];
    },

    cl_zIndex() {
      return this.variantClassList("z", "zIndex");
    },

    cl_color() {
      return this.variantClassList("text", "color");
    },
    cl_bgGradient() {
      return [
        ...this.variantClassList("bg-gradient-to", "bgGradientTo"),
        ...this.variantClassList("from", "bgFrom"),
        ...this.variantClassList("via", "bgVia"),
        ...this.variantClassList("to", "bgTo"),
      ];
    },
    cl_bgRepeat() {
      return this.variantClassList("bg", "bgRepeat");
    },
    cl_bgSize() {
      return this.variantClassList("bg", "bgSize");
    },
    cl_bgOpacity() {
      return this.variantClassList("bg-opacity", "bgOpacity");
    },
    cl_bgImageOpacity() {
      return this.variantClassList("bg-image-opacity", "bgImageOpacity");
    },
    cl_bgPosition() {
      return this.variantClassList("bg", "bgPosition");
    },

    cl_bgClip() {
      return this.variantClassList("bg-clip", "bgClip");
    },

    cl_bgImage() {
      const classes = [];
      if (this.isPropSet("bgImage")) {
        classes.push("bg-image");
      }
      return classes;
    },

    cl_gap() {
      return this.variantClassList("p", "gap");
    },

    cl_gapWrapper() {
      /**
       * We need to set the negetive value of a gap set
       * So manually converting all the values to negetive for gap wrapper
       */
      return this.variantClassList("m", "gap", (o) => {
        if (o?.value != null) {
          o.value = -Math.abs(o.value);
          return o;
        }
      });
    },

    cl_spacer() {
      return spacers.map((prop) => this.variantClassList(prop, prop)).flat();
    },

    cl_rounded() {
      return rounded.map((prop) => this.variantClassList(prop, prop)).flat();
    },

    cl_inset() {
      return inset.map((prop) => this.variantClassList(prop, prop)).flat();
    },

    cl_border() {
      return border.map((prop) => this.variantClassList(prop, prop)).flat();
    },

    cl_borderStyle() {
      return this.variantClassList("border", "borderStyle");
    },

    cl_borderColor() {
      return this.variantClassList("border", "borderColor");
    },

    cl_display() {
      return this.variantClassList(false, "display");
    },

    cl_flexWrap() {
      return this.variantClassList("flex", "flexWrap");
    },

    cl_flexShrink() {
      return this.variantClassList("flex-shrink", "flexShrink", (o) => {
        if (o.value == 1) {
          o.value = true;
        }
        return o;
      });
    },

    cl_flexGrow() {
      return this.variantClassList("flex-grow", "flexGrow", (o) => {
        if (o.value == 1) {
          o.value = true;
        }
        return o;
      });
    },

    cl_order() {
      return this.variantClassList("order", "order");
    },

    cl_overflow() {
      return this.variantClassList("overflow", "overflow");
    },

    cl_rotate() {
      return this.variantClassList("rotate", "rotate");
    },

    cl_direction() {
      return this.variantClassList("flex", "direction");
    },

    cl_textAlign() {
      return this.variantClassList("text", "textAlign");
    },

    cl_position() {
      return this.variantClassList(false, "position");
    },

    /**
     * ignoreProps helps to ignore classes added in specific situations
     * being used in media ratio
     */
    cl_w() {
      if (this.ignoreProps?.includes("w")) {
        return [];
      } else {
        return this.variantClassList("w", "w");
      }
    },

    cl_h() {
      if (this.ignoreProps?.includes("h")) {
        return [];
      } else {
        return this.variantClassList("h", "h");
      }
    },

    cl_opacity() {
      return this.variantClassList("opacity", "opacity");
    },

    cl_shadow() {
      return this.variantClassList("shadow", "shadow");
    },

    cl_bg() {
      return this.variantClassList("bg", "bg");
    },

    cl_items() {
      return this.variantClassList("items", "items");
    },

    cl_justify() {
      return this.variantClassList("justify", "justify");
    },

    cl_transform() {
      if (this.mergedProps.transform) {
        return ["transform"];
      }
      return [];
    },

    cl_classListByUser() {
      return this.mergedProps.classListByUser || [];
    },

    finalClassList() {
      return [
        ...this.cl_isHidden,
        ...this.cl_color,
        ...this.cl_w,
        ...this.cl_h,
        ...this.cl_shadow,
        ...this.cl_opacity,
        ...this.cl_bg,
        ...this.cl_bgRepeat,
        ...this.cl_bgImage,
        ...this.cl_bgPosition,
        ...this.cl_bgOpacity,
        ...this.cl_bgImageOpacity,
        ...this.cl_bgSize,
        ...this.cl_bgGradient,
        ...this.cl_bgClip,
        ...this.cl_display,
        ...this.cl_overflow,
        ...this.cl_direction,
        ...this.cl_rounded,
        ...this.cl_textAlign,
        ...this.cl_position,
        ...this.cl_inset,
        ...this.cl_border,
        ...this.cl_order,
        ...this.cl_flexWrap,
        ...this.cl_flexShrink,
        ...this.cl_flexGrow,
        ...this.cl_items,
        ...this.cl_justify,
        ...this.cl_rotate,
        ...this.cl_borderColor,
        ...this.cl_borderStyle,
        ...this.cl_zIndex,
        ...this.cl_spacer,
        ...this.cl_transform,
        ...this.cl_classListByUser,
      ];
    },
  },
};
