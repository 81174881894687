<template>
  <form
    v-show="!mergedProps.isHidden"
    class="form"
    :class="finalClassList"
    :x-data="`form('${fieldsObject}','${formConfig}')`"
    ref="form"
    x-bind:class="{'loader text-gray-500 opacity-50':loading}"
    v-bind="attrList"
    :style="style"
    @click="select"
    v-pm-segment
  >
    <!-- ALERTS -->
    <!-- The key is required to hard refresh Alpine Elements -->
    <pm-alert
      :key="`form-success--${mergedProps.state}`"
      x-show="success"
      v-bind="childSegment('success')"
    />
    <pm-alert
      :key="`form-error--${mergedProps.state}`"
      x-show="error"
      v-bind="childSegment('error')"
    />
    <!-- FIELD -->
    <div class="form__fields" :class="scl_fields">
      <template >
        <div class="form__field" v-for="(field, index) in mergedProps.fields" :key="`field-${index}`">
          <pm-text
            v-if="field.type != 'hidden'"
            v-bind="childSegment('label', labelProps, index)"
          />

          <!-- 
            pm-field is special component which is not editable from editor
            this component inherits the props from the pm-form.
            This is designed intentionally to let users configure a form
            from a single place.
          -->
          <pm-field ref="field" :ref-index="index" v-bind="field" />
          <pm-text
            v-bind="
              childSegment(
                'description',
                descriptionProps,
                index
              )
            "
          />
        </div>
      </template>

      <div class="form__field">
        <pm-button :locked="['onClick']" v-bind="childSegment('submit')" />
      </div>
    </div>
  </form>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-form",
  props,
  mixins: [segment],

  /**==============================================
   * * INFO
   * Pagemaker Editor does the injection.
   * Injections here require default values because:
   * The Pagemaker Designer does not inject
   * and hence it should not throw error.
   *=============================================**/
  inject: {
    PAGE_DATA: {
      default: {},
    },
    MODULE_UUID: {
      default: "MODULE_UUID",
    },
  },

  computed: {
    labelProps() {
      return {
        propsByIndex: this.mergedProps.fields.map((field) => ({
          content: field.label || "Field",
        })),
      };
    },
    descriptionProps() {
      return {
        propsByIndex: this.mergedProps.fields.map((field) => ({
          content: field.description || 'Description',
        })),
      };
    },
    scl_fields() {
      return this.variantClassList("space-y", "gap");
    },

    fieldsObject() {
      const fields = {};
      this.mergedProps.fields.forEach((field) => {
        if (field.type == "checkbox") {
          fields[field.name] = [];
          // field.options.split(",").forEach(item => {
          //   fields[field.name][item] = false;
          // });
        } else {
          fields[field.name] = field.value || null;
        }
      });
      return JSON.stringify(fields);
    },
    formConfig() {
      const config = {
        state: this.mergedProps.state,
        type: this.mergedProps.type,
        integrationId: this.mergedProps.integrationId,
        moduleUuid: this.MODULE_UUID,
        campaignHash: this.PAGE_DATA.campaignHash,
        successRedirectUrl: this.mergedProps.successRedirectUrl,
        onSuccess: this.mergedProps.onSuccess,
        successRedirectUrlTarget: this.mergedProps.successRedirectUrlTarget,
      };
      return JSON.stringify(config);
    },
  },
};
</script>
