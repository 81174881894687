<script>
/**
 * Created a render function instead of using a template.
 * v-show does not work with top level element. Hence it does not work when module uses the pm-wrapper as segment for top level node.
 */

import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-wrapper",
  mixins: [segment],
  props,
  render(createElement) {
    return createElement(
      this.segmentTag("div"),
      {
        style: this.style,
        attrs: this.attrList,
        class: [
          this.mergedProps.isHidden ? "wrapper hidden" : "wrapper",
          ...this.finalClassList,
        ],
        on: {
          click: this.select,
        },
        directives: [
          {
            name: "pm-segment",
            value: true,
          },
        ],
      },
      [this.$slots.default]
    );
  },
};
</script>
