import props from "./../../../src-utils/propDefiner";

export default {
  ...props("value", String, "pm-media"),
  ...props("source", String, "pm-media"),
  ...props("alt", String, "pm-media"),
  ...props("ratioW", Number, "pm-media"),
  ...props("ratioH", Number, "pm-media"),
  ...props(
    "mediaFit",
    {
      type: String,
      default: "contain",
    },
    "pm-media"
  ),
  ...props(
    "mediaPosition",
    {
      type: String,
      default: "left",
    },
    "pm-media"
  ),

  //Default Overrides
  /**
   * Need default value of overflow as hidden for media to
   * automatically hide content outside of corners in case of
   * border radius and embeded items.
   */
  ...props("overflow", {
    type: String,
    default: "hidden",
  }),

  /**
   * If the display property is not set explicitly,
   * media should be inline-flex. This helps to center it when parent alignment is set.
   */
  ...props("display", {
    type: String,
    default: "inline-flex",
  }),
};
