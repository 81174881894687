/**==============================================
 * Helper function to define props for the segment.
 * Takes the prop configuration into the account
 * and generates variant props.
 *
 * * INPUT
 * prop = "color"
 * propConfig = {type:String, default:"red-500"}
 * segmentTag = "pm-text"
 *
 * * OUTPUT
 * {
 *    color:{type:String, default:"red-500"},
 *    sm:color:{type:String, default:"red-500"},
 *    md:color:{type:String, default:"red-500"},
 *    ...
 * }
 *=============================================**/

import generatePropVariantsByTag from "./generatePropVariantsByTag";

export default (prop, propConfig, segmentTag) => {
  if (typeof propConfig != "object" || Array.isArray(propConfig)) {
    propConfig = {
      type: propConfig,
    };
  }

  return generatePropVariantsByTag(segmentTag, prop).reduce((o, item) => {
    let variantPropConfig;

    //If the default is set to undefined, we need to apply it to all variants
    //To avoid false value being added in module initialization
    if (propConfig.type === Boolean) {
      variantPropConfig = { ...propConfig, default: undefined };
    }
    //Use propConfig as it is for base variant
    else if (item == prop) {
      variantPropConfig = propConfig;
    }
    //Ignore the default values for the responsive and other variants
    else {
      variantPropConfig = propConfig.type;
    }

    return Object.assign(o, {
      //Avoiding setting default values on responsive props
      //Not doing this will add all the classes with same prop value
      [item]: variantPropConfig,
    });
  }, {});
};
