import mediaProps from "../pm-media/props";
import props from "./../../../src-utils/propDefiner";

export default {
  //Need to define as prop because these
  ...mediaProps,

  ...props("count", Number, "pm-media-group"),
  ...props(
    "value",
    {
      type: [ Array, String],
      default: () => [],
    },
    "pm-media-group"
  ),

  ...props(
    "itemsInRow",
    {
      type: Number,
      default: 4,
    },
    "pm-media-group"
  ),
};
