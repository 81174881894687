import props from "./../../../src-utils/propDefiner";

export default {
    ...props("name", { type: String }, "pm-input"),
    ...props("type", { type: String, default: "text" }, "pm-input"),
    ...props("disabled", { type: Boolean, default: undefined }, "pm-input"),
    ...props("value", { type: [String, Number], default: undefined }, "pm-input"),
    ...props("min", { type: Number, default: 1 }, "pm-input"),
    ...props("max", { type: Number, default: 10 }, "pm-input"),
};
