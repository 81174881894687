<template>
  <component
    v-show="!mergedProps.isHidden"
    class="alert"
    :class="finalClassList"
    v-bind="attrList"
    :style="style"
    @click="select"
    v-pm-segment
    :is="segmentTag('div')"
  >
    <pm-icon v-bind="childSegment('icon')" />
    <div>
      <pm-text v-bind="childSegment('title')" />
      <pm-text v-bind="childSegment('message')" />
    </div>
  </component>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-alert",
  mixins: [segment],
  props,
};
</script>
