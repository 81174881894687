import props from "./../../../src-utils/propDefiner";

const error = {
  display: "flex",
  items: "center",
  border: 4,
  p: 4,
  rounded: "lg",
  borderColor: "red-100",
  child: {
    icon: {
      w: 16,
      mr: 2,
      flexShrink: 0,
      name: "ph:warning-circle-duotone",
      color: "red-500",
    },
    title: {
      color: "gray-900",
      size: "lg",
      weight: "bold",
      leading: "none",
      content: "Oops!",
    },
    message: {
      mt: 1,
      size: "sm",
      color: "gray-900",
      content:
        "There was an error while trying to submit form. Please try again.",
    },
  },
};

const success = {
  borderColor: "green-100",
  display: "flex",
  items: "center",
  border: 4,
  p: 4,
  rounded: "lg",
  child: {
    icon: {
      w: 16,
      mr: 2,
      flexShrink: 0,
      name: "ph:check-circle-duotone",
      color: "green-500",
    },
    title: {
      color: "gray-900",
      size: "lg",
      weight: "bold",
      leading: "none",
      content: "Success",
    },
    message: {
      mt: 1,
      size: "sm",
      color: "gray-900",
      content: "The form has been submitted successfully!",
    },
  },
};

export default {
  ...props("fields", { type: Array, default: () => [] }, "pm-form"),
  ...props("name", { type: String, default: "Form" }, "pm-form"),
  ...props("integrationId", { type: Number }, "pm-form"),
  ...props("type", { type: String, default: "custom" }, "pm-form"),
  ...props("state", { type: String, default: "initial" }, "pm-form"),
  ...props("gap", { type: Number, default: 4 }, "pm-form"),
  ...props("onSuccess", { type: String }, "pm-form"),
  ...props("successRedirectUrl", { type: String }, "pm-form"),
  ...props("successRedirectUrlTarget", { type: String }, "pm-form"),

  child: {
    type: Object,
    default: () => ({
      success: { ...success, mb: 8 },
      error: { ...error, mb: 8 },
      label: {
        size: "sm",
        tag: "label",
        display: "block",
        color: "gray-500",
        mb: 1,
      },
      submit: {
        type: "submit",
        preset: "primary-solid-md-rounded",
        w: "full",
        child: {
          label: {
            content: "Submit",
          },
        },
      },
      description: {
        isHidden: true,
        size: "xs",
        color: "gray-500",
      },
    }),
  },
};
