import props from "./../../../src-utils/propDefiner";

export default {
  ...props("activeColor", { type: String, default: "amber-500" }, "pm-rating"),

  ...props(
    "inactiveColor",
    { type: String, default: "amber-200" },
    "pm-rating"
  ),

  ...props("size", { type: Number, default: 6 }, "pm-rating"),
  ...props("stars", { type: Number, default: 5 }, "pm-rating"),
  ...props("value", { type: Number, default: 4 }, "pm-rating"),
};
