<template>
  <component
    v-show="!mergedProps.isHidden"
    :class="scl_container"
    v-bind="attrList"
    :style="style"
    @click="select"
    v-pm-segment
    :is="segmentTag('div')"
  >
    <slot></slot>
  </component>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-container",
  props,
  mixins: [segment],
  computed: {
    scl_container() {
      const classes = [...this.finalClassList];
      if (this.mergedProps.fluid) {
        classes.push(`container-fluid`);
      } else {
        classes.push(`container`);
      }
      return classes;
    },
  },
};
</script>
