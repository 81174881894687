import common from "../src-config/common-props-variants";
import segment from "../src-config/segment-props-variants";

/**
 * While getting prop variant config, the priority is given to segment specific tags.
 * If segment speficic prop is not defined, then look into common props.
 *
 * tag: pm-text
 * prop: color
 */
export default (tag, prop) => {
  if (segment[tag] && segment[tag][prop]) {
    return segment[tag][prop];
  } else if (common[prop]) {
    return common[prop];
  } else {
    // console.warn("Prop variants are not defined for:", segmentTag, prop);
    return [];
  }
};
