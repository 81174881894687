<template>
  <component
    v-show="!mergedProps.isHidden"
    class="media-group"
    v-bind="attrList"
    :style="style"
    @click="select"
    v-pm-segment
    :is="segmentTag('div')"
  >
    <div
      class="mt-2 grid gap-2"
      :class="scl_mediaGrid"
      :data-pm-variable="getVariable('value')"
    >
      <button
        class="bg-transparent border-0 p-0 cursor-pointer focus:outline-none"
        type="button"
        v-for="(item, index) in mergedProps.value"
        :key="`${item}-${index}`"
        :x-on:click="`change('${replaceVariableValue(item)}')`"
        :x-ref="`item-${index}`"
        :data-value="replaceVariableValue(item)"
      >
        <pm-media v-bind="mergedProps" :value="mergedProps.value[index]" />
      </button>
    </div>
  </component>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-media-group",
  mixins: [segment],
  props,
  computed: {
    scl_mediaGrid() {
      return [...this.variantClassList("grid-cols", "itemsInRow")];
    },
  },

  inject: ["window"],

  watch: {
    "mergedProps.value"(newValue) {
      this.window?.dispatchEvent(
        new CustomEvent("pm-media-group-change", {
          detail: {
            items: newValue.map(item => this.replaceVariableValue(item)),
            id: this.$el.getAttribute("data-gallery-id"),
          },
        })
      );
    },
  },
};
</script>
