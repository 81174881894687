import Vue from "vue";
import { get } from "lodash-es";

export default Vue.component("ModuleRenderer", {
    /**
     * @param {props} module it's module Json structuer that will be rendered
     */
    props: {
        name: {
            type: String,
            default: null,
        },
        baseUrl: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            module: null,
        };
    },
    created() {
        this.fetchModuleJson(this.name);
    },
    methods: {
        /**========================================================================
         * * INFO
         * This function fetches the Built Vue JSON Component from the URL
         * Load JSON using render function (file: module-renderer.js)
         *
         * More Info:
         * https://v2.vuejs.org/v2/guide/render-function
         *
         *========================================================================**/
        fetchModuleJson(name) {
            const moduleURL = `${this.baseUrl}/${name}/component.json`;

            return fetch(moduleURL)
                .then((res) => res.json())
                .then((json) => {
                    this.module = json;
                    this.$nextTick(() => {
                        this.$emit("success");
                    });
                })
                .catch((err) => {
                    this.$emit("error", err);
                });
        },
    },
    render: function (createElement) {
        if (!this.module) return;

        /**
         * ?  ++++++++ Example of the node ++++++++
         * <template #default={value}> <pm-wraper :value="value" bg="red"><pm-text :value="value"/> </pm-wraper> </template>
         * * Here value is scope of default slot that will be passed to the children and bg is attrs of the pm-text componen.
         * ?  ++++++++ Example of the node ++++++++
         */

        /**
         * @param {Object} node it's a json structuer of the segment.
         * @param {Object} props it's a scope that will be passed to the component by default it's empty object.
         * * DFS( Depth-first search ) algorithm to create the component from the json structuer.
         * * As above example of the node, it will create the childern with the scoped value.
         * @returns vue Element
         */
        const createElementFromJson = (node, props = {}) => {
            /**
             * ? Here props is the scope of the slot and pass to every child of the slot. because value is provided nested in children.
             * *as above example of the node, here we pass the value to the children. So pm-text will get the value as a prop.
             */
            const children =
                node?.children?.map((child) =>
                    createElementFromJson(child, props)
                ) ?? [];

            const scopedSlots = {};
            for (const slot in node.scopedSlots) {
                /**
                 * @param {Object} scopes scope of the slot. psses to children so that they can access the value of the slot.
                 * @returns Array of children(vue Element) of the slot
                 */
                scopedSlots[slot] = function (scopes) {
                    return node.scopedSlots[slot].children.map((slotChild) => {
                        return createElementFromJson(slotChild, {...props, ...scopes});
                    });
                };
            }

            return createElement(
                node.name,
                {
                    scopedSlots,
                    ref: node.ref,
                    class: node.class,
                    props: node.attrs.reduce(
                        (a, p) => {
                            if(p.name === 'v-bind' && get(props, p.value)) {
                                a = { ...a,  ...get(props, p.value)};
                            }
                            else {
                                a[p.name] = get(props, p.value) ?? p.value;
                            }
                            return a;
                        },
                        /**
                         * * props is scoped value to merge with componet props.
                         * * as above example of the node, here we merge scope and attrs [ {value: "12", bg: 'red'} ].
                         * ? clone props to avoid mutation
                         */
                        { ...props }
                    ),
                    attrs: node.attrs.reduce(
                        (a, p) => {
                            if(p.name === 'v-bind' && get(props, p.value)) {
                                a = { ...a,  ...get(props, p.value)};
                            }
                            else {
                                a[p.name] = get(props, p.value) ?? p.value;
                            }
                            return a;
                        },
                        /**
                         * * props is scoped value to merge with componet props.
                         * * as above example of the node, here we merge scope and attrs [ {value: "12", bg: 'red'} ].
                         * ? clone props to avoid mutation
                         */
                        { ...props }
                    ),
                },
                children
            );
        };

        // * here we will allways get the root node of the module. but for all senarios we will return array of children.
        return this.module.segments.map((node) => createElementFromJson(node));
    },
});
