<template>
  <!-- Created a wrapper to keep margin classes non-changable from editor -->
  <div class="row-wrapper" :class="cl_gapWrapper">
    <component
      v-show="!mergedProps.isHidden"
      class="row flex-wrap"
      :class="finalClassList"
      v-bind="attrList"
      :style="style"
      @click="select"
      v-pm-segment
      :is="segmentTag('div')"
    >
      <!-- cols.base is used to take the count of cols -->
      <div
        class="column"
        :class="scl_rowCol(i)"
        v-for="(col, i) in mergedProps.cols"
        :key="`col-${i}`"
      >
        <slot :name="i" :col-index="i" />
      </div>
    </component>
  </div>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-row",
  mixins: [segment],
  props,

  methods: {
    colWidth(size) {
      return size == 12 ? `full` : `${size}/12`;
    },

    scl_rowCol(i) {
      return [
        ...this.cl_gap, //Padding
        ...this.variantClassList("w", "cols", (o) => {
          if (o.value) {
            o.value = this.colWidth(o.value[i]);
            return o;
          }
        }),
      ];
    },
  },
};
</script>
