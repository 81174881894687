<template>
  <pm-wrapper v-bind="{ ...mergedProps, ...childSegment('wrapper') }" @click="select" v-pm-segment
    class="flex-grow-0 flex-shrink-0" :x-ref="refIndex + 1">
    <slot></slot>
  </pm-wrapper>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-carousel-item",
  mixins: [segment],
  props,
  computed: {

  },
};
</script>