<template>
  <!-- 
  We need to use default props here instead of mergedProps
  Because the props are passed through pm-form and those values are already merged.
 -->
  <div class="field" :class="finalClassList" v-bind="attrList" :style="style">
    <!-- SELECT -->
    <select
      class="select form-select"
      :class="scl_fieldInput"
      v-if="type == 'select'"
      :name="name"
      :required="required"
      :x-model="`fields.${name}`"
    >
      <option value="">{{ placeholder || "Select" }}</option>
      <option
        v-for="(option, index) in options"
        :key="`option-${option}-${index}`"
        :value="option"
      >
        {{ option }}
      </option>
    </select>

    <!-- TEXTAREA -->
    <textarea
      class="textarea form-textarea"
      :class="scl_fieldInput"
      v-else-if="type == 'textarea'"
      :name="name"
      rows="5"
      :placeholder="placeholder"
      :required="required"
      :x-model="`fields.${name}`"
    />

    <!-- RADIO -->
    <div v-else-if="type == 'radio'" class="radio space-y-2">
      <label
        v-for="(option, index) in options"
        class="radio__option flex items-center"
        :key="`radio-${option}-${index}`"
      >
        <input
          :name="name"
          type="radio"
          :value="option"
          class="mr-2 form-radio"
          :x-model="`fields.${name}`"
          :required="required"
        />
        <span>{{ option }}</span>
      </label>
    </div>

    <!-- CHECKBOX -->
    <div v-else-if="type == 'checkbox'" class="checkbox space-y-2">
      <label
        v-for="(option, index) in options"
        class="checkbox__option flex items-center"
        :key="`checkbox-${option}-${index}`"
      >
        <input
          class="mr-2 form-checkbox"
          :name="option"
          type="checkbox"
          :value="option"
          :x-model="`fields.${name}`"
          :required="required"
        />
        <span>{{ option }}</span>
      </label>
    </div>

    <!-- TEXTBOX -->
    <input
      v-else
      class="input form-input"
      :class="scl_fieldInput"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :required="required"
      :x-model="`fields.${name}`"
    />
  </div>
</template>

<script>
import segment from "../../mixins/segment";
import { kebabCase } from "lodash-es";
import props from "./props";

export default {
  name: "pm-field",
  props,
  mixins: [segment],

  data() {
    return {
      kebabCase,
    };
  },

  computed: {
    scl_fieldInput() {
      const classes = ["w-full", "h-10", "bg-gray-100"];
      switch (this.type) {
        case "select":
          classes.push("px-3", "rounded-md");
          break;

        default:
          classes.push("px-3", "rounded-md");
          break;
      }
      return classes;
    },
  },
};
</script>
