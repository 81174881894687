<template>
  <component
    @click="select"
    v-pm-segment
    v-show="!mergedProps.isHidden"
    :is="segmentTag('div')"
    class="menu"
    :class="finalClassList"
    v-bind="attrList"
    :style="style"
    x-data="{ open: false }"
    x-bind:class="{ 'menu--open': open }"
  >
    <!-- Toggle -->
    <div class="menu__toggle" :class="toggleClassList">
      <pm-button v-bind="childSegment('toggle')" x-on:click="open = !open" />
    </div>

    <!-- Overlay -->
    <div
      class="menu__overlay"
      x-show="open==true"
      :class="menuOverlayClassList"
    >
      <pm-wrapper v-bind="childSegment('overlay')" />
    </div>

    <!-- Close -->
    <div class="menu__close" x-show="open==true" :class="closeClassList">
      <pm-button v-bind="childSegment('close')" x-on:click="open = false" />
    </div>

    <!-- Items -->
    <div
      class="menu__items"
      :x-bind:class="`{'transform-none':open}`"
      :class="menuClassList"
      x-on:click="open = false"
    >
      <pm-repeater v-bind="childSegment('items')" #default="{ index }">
        <pm-button v-bind="childSegment('item', {}, index)" />
      </pm-repeater>
    </div>
  </component>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

const positions = {
  left: {},
  right: ["justify-end"],
  bottom: ["items-end"],
  top: ["items-start"],
};

const translateClasses = {
  left: "translate-x-full",
  right: "-translate-x-full",
  bottom: "-translate-y-full",
  top: "translate-y-full",
};

export default {
  name: "pm-menu",
  mixins: [segment],
  props,

  computed: {
    translateClass() {
      return translateClasses[this.mergedProps.itemsPosition];
    },

    toggleClassList() {
      return [`${this.mergedProps.expandFrom}:hidden`];
    },

    closeClassList() {
      return [`${this.mergedProps.expandFrom}:hidden`];
    },

    menuOverlayClassList() {
      return ["z-30", `${this.mergedProps.expandFrom}:hidden`];
    },

    menuClassList() {
      return [
        "fixed",
        "inset-0",
        "z-40",
        "transform-gpu",
        "transition-transform",
        "duration-200",
        "flex",
        this.translateClass,
        positions[this.mergedProps.itemsPosition],
        `${this.mergedProps.expandFrom}:static`,
        `${this.mergedProps.expandFrom}:block`,
        `${this.mergedProps.expandFrom}:transform-none`,
      ];
    },
  },
};
</script>
