<template>
  <component
    v-show="!mergedProps.isHidden"
    class="accordion"
    :class="finalClassList"
    v-bind="attrList"
    :style="style"
    @click="select"
    v-pm-segment
    :is="segmentTag('div')"
    x-data="accordion()"
  >
    <pm-repeater
      v-bind="
        childSegment('items', {
          count: 1,
        })
      "
      #default="{ index }"
    >
      <!-- ITEM -->
      <pm-wrapper v-bind="childSegment('item', {}, index)">
        <!-- HEADER -->
        <pm-wrapper
          :x-on:click="`toggle(${index})`"
          v-bind="childSegment('header', {}, index)"
        >
          <slot name="header" :index="index" />

          <pm-icon
            :x-show="`open.includes(${index})`"
            v-bind="childSegment('closeIcon', {}, index)"
          />
          <pm-icon
            :x-show="`!open.includes(${index})`"
            v-bind="childSegment('openIcon', {}, index)"
          />
        </pm-wrapper>

        <!-- BODY -->
        <pm-wrapper
          :x-show="`open.includes(${index})`"
          v-bind="childSegment('body', {}, index)"
        >
          <slot name="body" :index="index" />
        </pm-wrapper>
      </pm-wrapper>
    </pm-repeater>
  </component>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-accordion",
  mixins: [segment],
  props,
};
</script>
