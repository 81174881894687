<template>
  <div
    class="gallery"
    v-show="!mergedProps.isHidden"
    v-bind="attrList"
    :style="style"
    v-pm-segment
    @click="select"
    x-data="gallery()"
    x-bind="listener"
  >
    <pm-media
      v-bind="childSegment('preview')"
      source="gallery"
      :locked="['value']"
    />
    <pm-media-group v-bind="childSegment('items')" x-bind:data-gallery-id="id" />
  </div>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-gallery",
  props,
  mixins: [segment],
};
</script>
