<template>
  <div class="font-body antialiased">
    <slot :loading="loading" />
  </div>
</template>

<script>
import WebFont from "webfontloader";
import { getScript } from "@pagemaker.io/tailwind"


export default {
  props: {
    get: {
      type: Function,
      required: true,
    },
    // Context of the iframe is required hence passed via prop
    window: {
      default: () => window,
    },
    // Context of the iframe is required hence passed via prop
    document: {
      default: () => document,
    },
    // Context of the iframe is required hence passed via prop
    webFontContext: {},
  },

  provide() {
    return {
      window: this.window,
    };
  },

  data() {
    return {
      loading: false,
    };
  },

  created() {
    this.init();
  },

  methods: {
    async init() {
      this.loading = true;

      this.window.pagemaker = {
        mode: this.MODE,
      };

      this.get().then(async ({ js, commonJs, fonts, colors }) => {
        await Promise.all([...commonJs.map((item) => this.loadJs(item.url))]);
        
        await Promise.all([...js.map((item) => this.loadJs(item.src))]);
        this.loadWebFonts(fonts);
        this.document.getElementsByTagName("head")[0].appendChild(getScript({fonts, colors}));
        // const pluginsModule = () => import("@pagemaker.io/plugins");
        // await pluginsModule()
        this.loading = false;
      });
    },

    loadJs(url, id) {
      return new Promise((resolve, reject) => {
        const script = this.document.createElement("script");
        script.type = "text/javascript";
        //script.async = true;
        script.onload = function () {
          resolve();
        };
        script.onerror = function () {
          console.error("Failed to load the script: " + url);
          resolve();
        };
        script.src = url;
        if (id) {
          script.id = id;
        }
        this.document.getElementsByTagName("head")[0].appendChild(script);
      });
    },

    loadWebFonts(fonts) {
      const { display, body } = fonts;

      const fontsToLoad = [];
      if (display.family) {
        fontsToLoad.push(this.fontSerializer(display));
      }
      if (body.family) {
        fontsToLoad.push(this.fontSerializer(body));
      }

      return new Promise((resolve, reject) => {
        if (fontsToLoad.length) {
          WebFont.load({
            google: {
              families: fontsToLoad,
            },
            active() {
              resolve();
            },
            fontinactive() {
              console.warn("Pagemaker: Fonts might not have loaded!");
              resolve();
            },
            inactive() {
              console.warn("Pagemaker: Fonts might not have loaded!");
              resolve();
            },
            context: this.webFontContext,
          });
        }
      });
    },

    fontSerializer(config) {
      const { family, light, regular, bold } = config;
      const weights = [...new Set([light, regular, bold])]; //Add unique weights only.
      return `${family}:${weights.join(",")}`;
    },
  },
};
</script>
