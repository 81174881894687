<template>
  <component
    v-show="!mergedProps.isHidden"
    class="number-input"
    :class="finalClassList"
    v-bind="attrList"
    :style="style"
    @click="select"
    v-pm-segment
    :is="segmentTag('div')"
    :x-data="`{ qty: ${mergedProps.min}, minQty:${mergedProps.min}, maxQty:${mergedProps.max}  }`"
  >
    <pm-button
      x-on:click="qty--"
      v-bind="childSegment('removeButton', {})"
      x-bind:disabled="qty <= minQty"
    />

    <pm-input
      :disabled="mergedProps.inputDisabled"
      v-bind="childSegment('qtyInput', {})"
    />

    <pm-button
      x-on:click="qty++"
      v-bind="childSegment('addButton', {})"
      x-bind:disabled="qty >= maxQty"
    />
  </component>
</template>

<script>
import props from "./props";
import segment from "../../mixins/segment";

export default {
  name: "pm-number-input",
  mixins: [segment],
  props,
};
</script>
