<template>
  <component
    v-show="!mergedProps.isHidden"
    class="repeater"
    :class="finalClassList"
    v-bind="attrList"
    :style="style"
    @click="select"
    v-pm-segment
    :is="segmentTag('div')"
  >
    <template v-for="i in mergedProps.count">
      <slot
        v-if="i <= mergedProps.max"
        :index="i - 1"
        :data="dataSet[i - 1] || dataSet[0]"
      />
    </template>
  </component>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-repeater",
  mixins: [segment],
  props,
};
</script>
