import props from "./../../../src-utils/propDefiner";

export default {
  ...props("options", { type: [String, Array] }, "pm-button-group"),
  ...props("type", { type: String, default: "button" }, "pm-button-group"),
  ...props("name", { type: String }, "pm-button-group"),

  //Common Props Override
  ...props("display", { type: String, default: "inline-flex" }),
  ...props("items", { type: String, default: "center" }),
  ...props("justify", { type: String, default: "center" }),
};
