<template>
  <component
    v-show="!mergedProps.isHidden"
    class="textbox"
    :class="finalClassList"
    v-bind="attrList"
    :style="style"
    @click="select"
    v-pm-segment
    :is="segmentTag('div')"
  >
    <input
      class="textbox__input"
      :name="mergedProps.name"
      x-bind:value="qty"
      :disabled="mergedProps.disabled"
    />
  </component>
</template>

<script>
import props from "./props";
import segment from "../../mixins/segment";

export default {
  name: "pm-input",
  mixins: [segment],
  props,
};
</script>

