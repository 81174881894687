/**
 * Returns props with all the variant props.
 * segmentTag: pm-text
 * prop: color
 * returns: color, sm:color, md:color
 */
import getPropVariantConfigByTag from "./getPropVariantConfigByTag";
import breakpoints from "../src-config/breakpoints";

export default (segmentTag, prop) => {
  let props = [prop];

  const variants = getPropVariantConfigByTag(segmentTag, prop);
  const hover = variants.includes("hover");
  const dark = variants.includes("dark");
  const responsive = variants.includes("responsive");

  if (hover) {
    props.push(`hover:${prop}`);
  }

  if (dark) {
    props.push(`dark:${prop}`);
  }

  if (responsive) {
    props.push(...breakpoints.map((breakpoint) => `${breakpoint}:${prop}`));

    if (dark) {
      props.push(
        ...breakpoints.map((breakpoint) => `${breakpoint}:dark:${prop}`)
      );
    }

    if (hover) {
      props.push(
        ...breakpoints.map((breakpoint) => `${breakpoint}:hover:${prop}`)
      );
    }

    if (dark && hover) {
      props.push(
        ...breakpoints.map((breakpoint) => `${breakpoint}:dark:hover:${prop}`)
      );
    }
  }

  return props;
};
