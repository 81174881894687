import commonSegmentProps from "./../../src-config/common-props";
import classList from "./classList";
import classUtils from "./classUtils";
import { cloneDeep, pickBy, merge, mergeWith, startCase, get } from "lodash-es";
const checkVariableRegex = /:::[\w\[\]\.]+(\|\|\|)?(https?:)?[^(:::)]*:::/
const variableRegex = /:::[\w\[\]\.]+(\|\|\|)?(https?:)?[^(:::)]*:::/g;

export default {
  props: commonSegmentProps,
  mixins: [classUtils, classList],

  /**==============================================
   * * INFO
   * Pagemaker Editor does the injection.
   * Injections here require default values because:
   * The Pagemaker Designer does not inject
   * and hence it should not throw error.
   *=============================================**/
  inject: {
    MODULE_UUID: {
      default: "MODULE_UUID",
    },
    SEGMENTS: {
      default: () => () => [],
    },
    SAVED_SEGMENTS: {
      default: () => () => [],
    },
    VARIABLES: {
      default: () => () => {},
    },
    TEMPLATE_TYPE: {
      default: () => () => [],
    },
  },

  computed: {
    segmentName() {
      const name = this.$vnode.data.ref;
      if (!name) {
        console.error("You must add 'ref' key to the segment");
      }
      return name;
    },

    /**
     * When theme is explicitly provided, the component is loaded outside editor.
     * Hence we need to consider this only because no store will be available.
     * The theme applying on editor side happens by store.
     */
    segmentData() {
      let data;
      const savedSegments = this.SAVED_SEGMENTS();
      if (savedSegments) {
        data = savedSegments.find((item) => item.name == this.segmentName);
      } else {
        data = this.SEGMENTS().find((item) => item.name == this.segmentName);
      }
      return data;
    },

    propsMergedWithIndex() {
      return pickBy(
        this.propsMergerByIndex(
          this.$props,
          this.$props.propsByIndex,
          this.refIndex
        ),
        (value) => value !== undefined
      );
    },

    mergedProps() {
      let mergedProps;

      /**
       * segmentData may not be available in following cases:
       * - Component is added for the first time in editor.
       * - Component loaded outside the editor.
       */
      if (this.segmentData) {
        const propsToOverride = this.propsMergerByIndex(
          this.segmentData.props,
          this.segmentData.propsByIndex,
          this.refIndex
        );

        mergedProps = this.propsMerger(
          this.propsMergedWithIndex,
          propsToOverride
        );
      } else {
        mergedProps = this.propsMerger(
          this.propsMergedWithIndex,
          {}
        );
      }

      return pickBy(mergedProps, (value) => value !== undefined);
    },

    attrList() {
      const attrs = {
        "data-ref": this.segmentName,
        "data-ref-index": this.refIndex,
        "data-label": startCase(this.segmentName),
      };

      if (this.mergedProps.idByUser) {
        attrs.id = this.mergedProps.idByUser;
      }

      if (
        (!this.mergedProps.tag || this.mergedProps.tag == "a") &&
        this.mergedProps.link
      ) {
        //href
        attrs.href = this.mergedProps.link;
      }

      //target
      if (attrs.href && this.mergedProps.linkTarget) {
        attrs.target = this.mergedProps.linkTarget;
      }

      return attrs;
    },

    style() {
      const style = {};
      if (this.mergedProps.bgImage) {
        style["--bg-image"] = `url('${this.mergedProps.bgImage}')`;
      }
      if (this.mergedProps.bgX) {
        style["--bg-x"] = this.mergedProps.bgX;
      }
      if (this.mergedProps.bgY) {
        style["--bg-y"] = this.mergedProps.bgY;
      }
      return style;
    },

    variables() {
      return this.VARIABLES();
    },
    templateType() {
      return this.TEMPLATE_TYPE();
    },
    contentSwitching() {
      return ["product_page", "promo_page"].includes(this.templateType);
    },
  },

  methods: {
    isVariable(prop) {
      const value = this.mergedProps[prop]
      return this.isVariableString(value)
    },
    isVariableString(str) {
      if(typeof str != "string") return false;
      return checkVariableRegex.test(str)
    },

    getVariable(prop) {
      if (this.isVariable(prop)) {
        return this.mergedProps[prop];
      } else {
        return null;
      }
    },

    // getVariableValue(prop) {
    //   if (this.isVariable(prop)) {
    //     let value = this.mergedProps[prop]
    //     return this.replaceVariableValue(value)
    //   } else {
    //     return null;
    //   }
    // },

    replaceVariableValue(value) {
      if(typeof value != "string") return value
      if(!checkVariableRegex.test(value)) return value

      const matches = value.match(variableRegex)
      for (let i = 0; i < matches.length; i++) {
        let match = matches[i]
        /**
         * Split the match to get the @variableKey and @defaultValue (:::media[0].url|||https://example.com/image.jpg:::)
         */
        const [variableKey, defaultValue] = match.replace(/:::/g, '').split('|||')
        const variableWithoutDefault = `:::${variableKey}:::`
        /**
         * variableWithoutDefault is used becaue if variable is null it should not print null on page.
         */
        const variableValue = get((this.variables ?? {}), variableKey, defaultValue) ?? (this.contentSwitching?variableWithoutDefault:defaultValue)
        value = value.replace(match, variableValue)
      }
      return value
    },

    segmentTag(defaultTag) {
      if (this.mergedProps.tag) {
        return this.mergedProps.tag;
      } else if (this.mergedProps.link) {
        return "a";
      } else {
        return defaultTag || "div";
      }
    },

    select(e) {
      if (this.IS_DESIGNER || !this.segmentName) return;

      e && e.preventDefault();
      e && e.stopPropagation();

      this.BUS.$emit("segment-select", {
        segmentIndex: this.refIndex,
        moduleUuid: this.MODULE_UUID,
        segmentName: this.segmentName,
      });
    },

    /**
     *
     * @param {string} name Segment Ref
     * @param {Object} defaultProps Default props to apply
     */
    childSegment(name, defaultProps, defaultRefIndex) {
      // Build Child Ref By Prepending parent Ref
      // TODO: Remove this check and force designer to add name.
      let ref = name;
      if (this.segmentName) {
        ref = this.segmentName + ">" + name;
      }

      /**
       * NOTE: RefIndex
       * The refIndex needs to be passed explicitly in a case where child elements
       * are used in for-loop. For example countdown segment.
       * Hence if refIndex is passed from parent just override defaulltRefIndex
       */
      if (this.refIndex != null && defaultRefIndex !== null) {
        console.warn("RefIndex Conflict!", this.segmentName);
      }
      // OLD
      // if (this.refIndex != null) {
      //   defaultRefIndex = this.refIndex;
      // }

      // NEW;
      defaultRefIndex =
        defaultRefIndex != null ? defaultRefIndex : this.refIndex;

      let propsFromParent = this.mergedProps.child?.[name];

      if (propsFromParent) {
        propsFromParent = this.propsMergerByIndex(
          propsFromParent,
          propsFromParent.propsByIndex,
          defaultRefIndex
        );
      }

      const mergedProps = this.propsMerger(defaultProps, propsFromParent);
      return {
        ref,
        refIndex: defaultRefIndex,
        ...mergedProps,
      };
    },

    /**
     *
     * @param {Object} defaultProps Default props defined when module is designed.
     * @param {Object} overrideProps Props which are provided by user from editor.
     * @returns Object
     */
    propsMerger(defaultProps, overrideProps) {
      return mergeWith(
        {},
        defaultProps,
        overrideProps,
        (defaultValue, savedValue, prop) => {
          // NOTE: ORDER IS IMPORTANT!

          /**
           * ORDER IS 1
           * When the prop value is set to be variable,
           * we need to give priority to variable over defaultProps instead of savedProps
           */
          // if (this.isVariable(prop)) {
          //   return this.getVariableValue(prop);
          // }

          /**
           * ORDER IS 2
           * When merging the props, we need to specially treat values which are Array
           * The normal merge, merges the array and does not consider removed items
           * merge([1,2,3],[4,5]) becomes => [4,5,3]
           * while desired result is => [4,5]
           * Hence we use mergeWith which will always return latest value
           */
          if (Array.isArray(defaultValue)) {
            return savedValue;
          }

          /**
           * When the prop is child, it represents the props of child segment.
           * Each key in child is representing a child segment props.
           * Hence we need to merge each of the keys individually.
           */
          if (prop == "child" && defaultValue && savedValue) {
            const keys = [
              ...new Set([
                ...Object.keys(defaultValue),
                ...Object.keys(savedValue),
              ]),
            ];
            const mergedChildProps = keys.reduce((value, key) => {
              value[key] = this.propsMerger(defaultValue[key], savedValue[key]);
              return value;
            }, {});
            return mergedChildProps;
          }

          return savedValue;
        }
      );
    },

    /**
     * When the segments are looped, each segment can have it's own props which will override common props.
     * props: Contains all the common props which are similar across all the instances
     * propsByIndex: Contains index specific props
     * index: Represents the index of segment. This value should be same as refIndex prop.
     */
    propsMergerByIndex(props, propsByIndex, index) {
      let mergedProps;
      if (index != null && propsByIndex?.[index]) {
        mergedProps = merge({}, props, propsByIndex[index]);
      } else {
        mergedProps = merge({}, props);
      }

      /**
       * When segment is initialized, the propsByIndex is available under component props, hence removing it.
       * When segment is loaded from the API, the `props` and `propsByIndex`  are difference keys and
       * following code may not have any effect.
       */
      delete mergedProps.propsByIndex; // props may contain

      return mergedProps;
    },
  },
};
