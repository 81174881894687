<template>
  <component
    v-show="!mergedProps.isHidden"
    class="shopify-product"
    :class="finalClassList"
    v-bind="attrList"
    :style="style"
    @click="select"
    v-pm-segment
    :is="segmentTag('div')"
    :data-pm-shopify-product="mergedProps.productId"
  >
    <slot
      v-bind="{
        titleAttrs,
        imageAttrs,
        priceAttrs,
        descAttrs,
        variantAttrs,
        cartBtnAttrs,
        quantityAttrs,
        imageGalleryPreviewAttrs,
        imageGalleryItemsAttrs,
        compareAtPriceAttrs,
      }"
    />
  </component>
</template>

<script>
import props from "./props";
import segment from "../../mixins/segment";

export default {
  name: "pm-shopify-product",
  mixins: [segment],
  props,

  provide() {
    return {
      VARIABLES: () => {
        return {
          ...this.VARIABLES,
          shopify_product_title: this.mergedProps.title,
          shopify_product_price: this.mergedProps.price,
          shopify_product_id: this.mergedProps.productId,
          shopify_product_bodyHtml: this.mergedProps.bodyHtml,
          shopify_product_variants: this.mergedProps.variants,
          shopify_product_image: this.mergedProps.image?.url,
          shopify_product_images: this.imageGalleryItems,
          shopify_product_attributes: this.mergedProps.attributes,
          shopify_product_compare_at_price: this.mergedProps.compareAtPrice,
          ...this.attributeVars,
        };
      },
    };
  },

  computed: {
    titleAttrs() {
      return this.childSegment(`title`, {
        content: ":::shopify_product_title:::",
      });
    },

    imageAttrs() {
      return this.childSegment(`image`, {
        value: ":::shopify_product_image:::",
        locked: ["value"],
      });
    },

    imageGalleryPreviewAttrs() {
      return this.childSegment(`preview`, {
        value: ":::shopify_product_image:::",
        locked: ["value"],
      });
    },

    imageGalleryItemsAttrs() {
      return {
        value: ":::shopify_product_images:::",
        locked: ["value"],
      };
    },

    priceAttrs() {
      return this.childSegment(`price`, {
        content: ":::shopify_product_price:::",
      });
    },

    compareAtPriceAttrs() {
      return this.childSegment(`compare_at_price`, {
        content: ":::shopify_product_compare_at_price:::",
      });
    },

    descAttrs() {
      return this.childSegment(`description`, {
        content: ":::shopify_product_bodyHtml:::",
      });
    },

    variantAttrs() {
      return this.childSegment(`variant`, {
        isHidden: !this.mergedProps.hasVariants,
      });
    },

    cartBtnAttrs() {
      return this.childSegment(`button`, {
        type: "submit",
      });
    },

    quantityAttrs() {
      return this.childSegment(`qtyInput`, {
        "data-pm-variable": ":::shopify_product_quantity:::",
        name: "quantity",
      });
    },

    attributeVars() {
      const productId = this.mergedProps.productId;
      return this.mergedProps.attributes.reduce(
        (vars, attribute, attributeIndex) => {
          vars[`shopify_product_attribute_${attributeIndex}_name`] =
            (productId != null ? productId + "-" : "") + attribute.name;

          vars[`shopify_product_attribute_${attributeIndex}_label`] =
            attribute.name;

          vars[`shopify_product_attribute_${attributeIndex}_options`] =
            attribute.options.map((option, optionIndex) => {
              return {
                label: `:::shopify_product_attribute_${attributeIndex}_option_${optionIndex}_label:::`,
                value: `:::shopify_product_attribute_${attributeIndex}_option_${optionIndex}_value:::`,
                id: (productId != null ? productId + "-" : "") + option.title,
              };
            });

          attribute.options.forEach((option, optionIndex) => {
            vars[
              `shopify_product_attribute_${attributeIndex}_option_${optionIndex}_label`
            ] = option.title;
            vars[
              `shopify_product_attribute_${attributeIndex}_option_${optionIndex}_value`
            ] = option.title;
          });

          return vars;
        },
        {}
      );
    },

    imageGalleryItems() {
      return this.mergedProps.images.map((image) => {
        return image.url;
      });
    },
  },
};
</script>
