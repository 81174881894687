<template>
  <component
    v-if="mergedProps.value"
    :is="mergedProps.value"
    v-bind="defaultProps[mergedProps.value]"
  ></component>
  <div v-else>Select!</div>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-custom",
  props,
  mixins: [segment],
  data() {
    return {
      defaultProps: {
        "pm-text": {
          ref: "text-1",
          content: "Say Hello!",
        },
        "pm-button": {
          ref: "button-1",
          bg: "primary-500",
          child: {
            label: {
              content: "Click Me!",
            },
          },
        },
      },
    };
  },
};
</script>
