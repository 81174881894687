import props from "./../../../src-utils/propDefiner";

export default {
  ...props("name", String, "pm-modal"),

  ...props(
    "size",
    {
      type: String,
      default: "md",
    },
    "pm-modal"
  ),

  child: {
    type: Object,
    default: () => ({
      wrapper: {
        bg: "white",
        w: "full",
        "sm:w": "1/2",
        "xl:w": "1/3",
        rounded: "xl",
        m: "auto",
      },
      header: {
        px: 8,
        py: 4,
        borderB: "default",
        borderColor: "gray-200",
        display: "flex",
        items: "center",
      },
      title: {
        weight: "bold",
        size: "xl",
        content: "Claim Your Coupon",
      },
      close: {
        color: "gray",
        h: 8,
        w: 8,
        justify: "center",
        rounded: "full",
        ml: "auto",
        onClick: "close_modal",
        child: {
          icon: { name: "mdi:close", w: 5, h: 5 },
        },
      },
    }),
  },
};
