<template>
  <component
    v-show="!mergedProps.isHidden"
    class="button-group"
    :class="finalClassList"
    v-bind="attrList"
    :style="style"
    @click="select"
    v-pm-segment
    :is="segmentTag('div')"
  >
    <pm-wrapper
      v-bind="childSegment('options', {}, index)"
      v-for="(option, index) in mergedProps.options"
      :key="`button-${option}-${index}`"
      :data-pm-variable="getVariable('options')"
    >
      <input
        class="hidden"
        :name="mergedProps.name"
        type="radio"
        :id="option.id"
        :key="`button-radio-${option}-${index}`"
        :value="replaceVariableValue(option.value)"
        :data-pm-variable="getVariableName(option.value)"
      />
      <label :for="option.id" class="button-group__label">
        <pm-button
          preset="primary-solid-md-rounded"
          class="button-group__button"
          v-bind="getButtonProps(option, index)"
        />
      </label>
    </pm-wrapper>
  </component>
</template>

<script>
import props from "./props";
import { omit } from "lodash-es";
import segment from "../../mixins/segment";

export default {
  name: "pm-button-group",
  props,
  mixins: [segment],

  methods: {
    getVariableName(value) {
      if (isVariableString(value)) {
        return value;
      }
      return undefined;
    },

    getValue(value) {
      const variableName = this.getVariableName(value);
      return variableName ? this.variables[variableName] : value;
    },

    getButtonProps(option, index) {
      let mergedProps = omit(this.mergedProps, [
        "options",
        "child",
        "refIndex",
      ]);
      return this.childSegment(
        "button",
        {
          ...mergedProps,
          child: {
            label: {
              content: option.label,
            },
          },
        },
        index
      );
    },
  },

  /*
   *  For shopify product variants we are passing only variable and
   *  based on variable we are fetching the options at run time.
   *  As the options are added run time the navigator menu layers
   *  are not updating accordingly that's why this watch is added
   *  on merged props options..
   */
  watch: {
    "mergedProps.options": {
      deep: true,
      handler(newValue) {
        this.BUS.$emit("refresh-active-module-layers", this.MODULE_UUID);
      },
    },
  },
};
</script>
