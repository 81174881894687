<template>
  <!-- 
    The unique :key is required here.
    When the segment is open in editor and user sets the expiry date,
    the countdown timer needs to update.
    The key helps to force re-initialize the element.
  -->
  <component
    v-show="!mergedProps.isHidden"
    class="countdown"
    :x-data="`countdown(${expiryDate || ''})`"
    :class="finalClassList"
    v-bind="attrList"
    :style="style"
    @click="select"
    v-pm-segment
    :is="segmentTag('div')"
    :key="expiryDate"
  >
    <slot :countdown="countdown">
      <template v-for="(duration, i) in Object.keys(countdown)">
        <pm-wrapper
          :key="duration"
          v-bind="
            childSegment('card', { display: 'flex', direction: 'col' }, i)
          "
        >
          <pm-text
            :content="countdown[duration]"
            :x-text="`countdown().${duration}`"
            v-bind="childSegment('number', {}, i)"
          />
          <!-- need to pass propsbyindex else it will render first content -->
          <pm-text
            v-bind="childSegment('duration', durationProps, i)"
          />
        </pm-wrapper>
        <pm-text
          v-if="duration != 'seconds'"
          v-bind="childSegment('separator', {}, i)"
          :key="`separator-${duration}`"
        />
      </template>
    </slot>
  </component>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-countdown",
  props,
  mixins: [segment],
  computed: {
    expiryDate() {
      let expiry = this.replaceVariableValue(this.mergedProps.expiry)
      return expiry
    },

    durationProps() {
      return {
        propsByIndex: Object.keys(this.countdown).map((duration) => ({
          content: duration,
        })),
      };
    },
    countdown() {
      return {
        days: "0",
        hours: "00",
        minutes: "00",
        seconds: "00",
      };
    },
  },
};
</script>
