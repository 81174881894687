import props from "./../../../src-utils/propDefiner";

export default {
  ...props("gap", Number, "pm-row"),
  ...props("cols", Array, "pm-row"),

  //Common Props Override
  ...props("display", {
    type: String,
    default: "flex",
  }),
};
