<template>
  <div
    v-show="!mergedProps.isHidden"
    class="grid-wrapper"
    :class="cl_gapWrapper"
  >
    <component
      class="grid"
      :class="scl_grid"
      v-bind="attrList"
      :style="style"
      @click="select"
      v-pm-segment
      :is="segmentTag('div')"
    >
      <pm-grid-item
        v-for="i in mergedProps.count"
        v-bind="childSegment('item', {}, i - 1)"
        :key="`item-${i}`"
        :class="cl_gap"
      >
        <slot :data="dataSet[i - 1] || dataSet[0]" :index="i - 1" />
      </pm-grid-item>
    </component>
  </div>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-grid",
  mixins: [segment],
  props,

  computed: {
    scl_grid() {
      const cols = this.variantClassList("grid-cols", "cols");
      return [...cols, this.finalClassList];
    },
  },
};
</script>
