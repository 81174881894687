import props from "./../../../src-utils/propDefiner";

export default {
  ...props(
    "disableContentEditing",
    {
      type: Boolean,
      default: undefined,
    },
    "pm-text"
  ),
  ...props("content", [String, Number], "pm-text"),
  ...props("variable", { type: [Boolean], default: false }, "pm-text"),
  ...props("family", String, "pm-text"),
  ...props("size", String, "pm-text"),
  ...props("weight", String, "pm-text"),
  ...props("leading", String, "pm-text"),
  ...props("transform", String, "pm-text"),
  ...props("fontStyle", String, "pm-text"),
  ...props("decoration", String, "pm-text"),
};
