export default {
  "pm-container": {
    fluid: [],
  },
  "pm-form": {
    gap: ["responsive"],
    fields: [],
    name: [],
    integrationId: [],
    type: [],
    state: [],
    onSuccess: [],
    successRedirectUrl: [],
    successRedirectUrlTarget: [],
  },
  "pm-grid": {
    gap: ["responsive"],
    count: [],
    cols: ["responsive"],
    dataSet: [],
  },
  "pm-grid-item": {
    span: ["responsive"],
  },
  "pm-media": {
    value: [],
    source: [],
    alt: [],
    ratioW: ["responsive"],
    ratioH: ["responsive"],
    mediaFit: ["responsive"],
    mediaPosition: ["responsive"],
  },
  "pm-media-group": {
    //Media Specific
    value: [],
    source: [],
    alt: [],
    ratioW: ["responsive"],
    ratioH: ["responsive"],
    mediaFit: ["responsive"],
    mediaPosition: ["responsive"],

    //Media Group Specific
    itemsInRow: ["responsive"],
    count: [],
  },
  "pm-modal": {
    size: ["responsive"],
    name: [],
  },
  "pm-row": {
    gap: ["responsive"],
    cols: ["responsive"],
  },
  "pm-text": {
    family: ["responsive"],
    size: ["responsive"],
    weight: ["responsive"],
    leading: ["responsive"],
    transform: [],
    fontStyle: [],
    decoration: [],
    content: [],
    disableContentEditing: [],
  },
  "pm-button": {
    onClick: [],
    "xOn:click": [],
    type: [],
  },
  "pm-countdown": {
    expiry: [],
  },
  "pm-field": {
    type: [],
    name: [],
    required: [],
    unique: [],
    placeholder: [],
    options: [],
  },
  "pm-icon": {
    name: [],
  },
  "pm-menu": {
    expandFrom: [],
    itemsPosition: [],
  },
  "pm-rating": {
    activeColor: [],
    inactiveColor: [],
    size: [],
    stars: [],
    value: [],
  },
  "pm-repeater": {
    count: [],
    size: [],
    dataSet: [],
    max: [],
  },
  "pm-code": {
    value: [],
  },
  "pm-carousel": {
    slidesToScroll: [],
    autoplay: [],
  }
};
