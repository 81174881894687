/**
 * NOTE ABOUT DEFAULT VALUES:
 * Try to keep the default values to undefined.
 * This will avoid non-set props and it's keys inside theme.
 */

import border from "./border-props";
import inset from "./inset-props";
import rounded from "./rounded-props";
import spacers from "./spacer-props";
import props from "../src-utils/propDefiner";

//Spacer
const spacerProps = spacers
  .map((prop) => props(prop, [String, Number]))
  .reduce((o, item) => Object.assign(o, item), {});

//Rounded
const roundedProps = rounded
  .map((prop) => props(prop, [String, Number]))
  .reduce((o, item) => Object.assign(o, item), {});

//Border
const borderProps = border
  .map((prop) => props(prop, [String, Number]))
  .reduce((o, item) => Object.assign(o, item), {});

//Inset
const insetProps = inset
  .map((prop) => props(prop, [String, Number]))
  .reduce((o, item) => Object.assign(o, item), {});

export default {
  /**
   * Configuration specific props
   */
  refIndex: Number, //If the element is in for-loop, an index of element
  refFolder: String, // For grouping layers
  classListByUser: Array,
  idByUser: String,
  propsByIndex: Array, //To provide index specific props

  /**
   * Hide the segment if true, should be used in every segment at top level
   * This is used at render level and not class level
   * HTML won't be rendered if this is true.
   */
  isHidden: {
    type: Boolean,
    default: undefined,
  },

  //Forcefully render provided HTML tag. Will ignore the link prop too.
  tag: String,

  // Individual Props to be disabled from editor
  locked: Array,

  // Individual Props Groups to be disabled from editor
  lockedGroups: Array,

  // A preset can be used to fetch a predefined set of props
  preset: String,

  // Initial props for child elements
  child: Object,

  /**
   * Disable group editing in some of the elements
   * This is useful in a case where we have added conditional props based on segment index.
   */
  disableGroupEditing: {
    type: Boolean,
    default: undefined,
  },

  /**
   * Tailwind Class Specific Props
   * Applies the classes based on values configured from editor.
   */
  ...spacerProps,
  ...roundedProps,
  ...borderProps,
  ...insetProps,

  ...props("link", String),
  ...props("linkTo", String),
  ...props("linkTarget", String),
  ...props("color", String),
  ...props("borderColor", String),
  ...props("bg", String),
  ...props("bgImage", String),
  ...props("bgSize", String),
  ...props("bgRepeat", String),
  ...props("bgPosition", String),
  ...props("bgFrom", String),
  ...props("bgVia", String),
  ...props("bgTo", String),
  ...props("bgGradientTo", String),
  ...props("bgClip", String),
  ...props("bgX", String),
  ...props("bgY", String),
  ...props("bgOpacity", Number),
  ...props("bgImageOpacity", Number), //Applies to before element which contains the background image
  ...props("opacity", Number),
  ...props("shadow", String),
  ...props("w", [String, Number]),
  ...props("h", [String, Number]),
  // ...props("shadow", String),
  ...props("display", String),
  ...props("direction", String), //Flex Direction
  ...props("items", String), //Form Flex Childs. Align Items
  ...props("justify", String), //Form Flex Childs. Justify Content
  ...props("order", [String, Number]), //Flex Order
  ...props("flexWrap", String),
  ...props("flexShrink", Number),
  ...props("flexGrow", Number),
  ...props("textAlign", String),
  ...props("position", String),
  ...props("overflow", String),
  ...props("transform", Boolean),
  ...props("rotate", Number),
  ...props("zIndex", [Number, String]),
};
