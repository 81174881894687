<template>
  <component
    v-show="!mergedProps.isHidden"
    class="rating"
    :class="finalClassList"
    v-bind="attrList"
    :style="style"
    @click="select"
    v-pm-segment
    :is="segmentTag('div')"
  >
    <slot :value="intValue" :stars="mergedProps.stars">
      <div class="flex items-center">
        <template v-if="intValue > 0">
          <pm-icon
            v-for="n in intValue"
            :color="mergedProps.activeColor"
            name="mdi:star"
            title="star"
            :w="mergedProps.size"
            :h="mergedProps.size"
            :key="`active-${n}`"
          />
        </template>
        <template v-if="mergedProps.stars - intValue > 0">
          <pm-icon
            v-for="n in mergedProps.stars - intValue"
            :color="mergedProps.inactiveColor"
            name="mdi:star"
            title="star"
            :h="mergedProps.size"
            :w="mergedProps.size"
            :key="`inactive-${n}`"
          />
        </template>
      </div>
    </slot>
  </component>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-rating",
  mixins: [segment],
  props,
  computed: {
    intValue() {
      return parseInt(this.mergedProps.value);
    },
  },
};
</script>
