import { kebabCase } from "lodash-es";
import generatePropVariantsByTag from "./../../src-utils/generatePropVariantsByTag";

export default {
  methods: {
    /**
     * Generate responsive classlist based on prop values
     * @param {String} rule
     * A prefix of the tailwind
     *
     * @param {String} prop
     * A prop to find for to generate classlist
     *
     * @param {Function} propAdaptor
     * If any operation needs to be applied before generating a class string
     */

    variantClassList(rule, prop, propAdaptor = (o) => o) {
      const props = generatePropVariantsByTag(this.$options.name, prop);

      return props
        .map((prop) => {
          const propArray = prop.split(":");
          propArray.pop();

          return propAdaptor({
            key: prop,
            modifier: propArray.join(":"),
            value: this.mergedProps[prop],
          });
        })
        .filter((o) => o?.value != null)
        .map((o) => this.generateClassString(o.modifier, rule, o.value));
    },

    isPropSet(prop) {
      const props = generatePropVariantsByTag(this.$options.name, prop);

      if (this.mergedProps[prop]) {
        return true;
      }
      return Object.keys(props)
        .map((modifier) => this.mergedProps[modifier]?.[prop])
        .some((value) => value != null);
    },

    generateClassString(modifier, rule, value) {
      let className = "";

      if (rule !== false) {
        rule = kebabCase(rule);
      }

      if (modifier) {
        className += modifier + ":";
      }
      if (typeof value == "number" && value < 0) {
        rule = "-" + rule;
        value = Math.abs(value);
      }
      if (value == "default" || value == "DEFAULT") {
        className += rule;
      } else if (rule === false) {
        className += value;
      } else if (value === true) {
        className += rule;
      } else {
        className += rule + "-" + value;
      }
      return className;
    },
  },
};
