<template>
  <div
    v-show="!mergedProps.isHidden"
    class="grid-item-wrapper"
    :class="scl_gridItem"
  >
    <component
      class="grid-item"
      :class="finalClassList"
      v-bind="attrList"
      :style="style"
      @click="select"
      v-pm-segment
      :is="segmentTag('div')"
    >
      <slot />
    </component>
  </div>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-grid-item",
  mixins: [segment],
  props,
  computed: {
    scl_gridItem() {
      return [...this.variantClassList("col-span", "span")];
    },
  },
};
</script>
