<template>
    <pm-repeater
        v-bind="{
            ...mergedProps,
            ...childSegment('items'),
        }"
        @click="select"
        #default="{ index }"
        v-pm-segment
        x-ref="view"
        display="flex"
        overflow="auto"
        position="relative"
        :locked="['display', 'overflow', 'position']"
    >
        <slot :index="index"></slot>
    </pm-repeater>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
    name: "pm-carousel-items",
    mixins: [segment],
    props,
    computed: {},
};
</script>
