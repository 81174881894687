<template>
  <div class="pm-module" :class="`pm-module--${name}`">
    <!-- 
      Always ignore the loading and Error blocks while generating HTML
    -->
    <Loader class="pm--ignore" v-if="loading" />
    <Error class="pm--ignore" v-else-if="error" :name="name" />
    <!--
      COMPONENT
      You might be confused why use v-show?
      We always need to mount the component to fetch the component
    -->
    <ModuleRenderer ref="module" :name="name" :base-url="baseUrl" v-show="!loading && !error" @success="onSuccess" @error="onError" />
  </div>
</template>


<script>
import Error from "./Error.vue";
import Loader from "./Loader.vue";
import ModuleRenderer from './../module-renderer.js'

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      error: false,
      loading: true,
      moduleJson: null,
    };
  },

  components: {
    Loader,
    Error,
    ModuleRenderer
  },

  computed: {
    baseUrl() {
      return import.meta.env.VITE_MODULE_BASE_URL
    },
  },
  methods: {
    getRef() {
      return this.$refs.module;
    },
    onSuccess() {
      this.loading = false;
      this.$emit("success");
    },

    onError(err) {
      console.error(err);
      this.$sentry.catch(err);

      this.error = true;
      this.$emit("error");
      this.loading = false;
    },
  },

  errorCaptured(err, vm, info) {
    this.onError(err);
  },
};
</script>
