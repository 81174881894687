import border from "./border-props";
import inset from "./inset-props";
import rounded from "./rounded-props";
import spacers from "./spacer-props";

const spacerProps = {};
spacers.forEach((prop) => {
  spacerProps[prop] = ["responsive"];
});

const roundedProps = {};
rounded.forEach((prop) => {
  roundedProps[prop] = ["responsive"];
});

const borderProps = {};
border.forEach((prop) => {
  borderProps[prop] = ["responsive"];
});

const insetProps = {};
inset.forEach((prop) => {
  insetProps[prop] = ["responsive"];
});

export default {
  ...spacerProps,
  ...roundedProps,
  ...borderProps,
  ...insetProps,

  tag: [],
  color: ["responsive", "dark", "hover"],
  borderColor: ["responsive", "dark", "hover"],
  bg: ["responsive", "dark", "hover"],
  bgSize: ["responsive"],
  bgRepeat: ["responsive"],
  bgPosition: [],
  bgFrom: ["responsive", "dark", "hover"],
  bgVia: ["responsive", "dark", "hover"],
  bgTo: ["responsive", "dark", "hover"],
  bgGradientTo: ["responsive", "dark", "hover"],
  bgClip: [],
  bgX: [],
  bgY: [],
  bgOpacity: ["responsive", "dark", "hover"],
  bgImage: [],
  bgImageOpacity: ["responsive", "hover"],
  opacity: ["responsive", "hover"],
  shadow: ["responsive", "hover"],
  w: ["responsive", "hover"],
  h: ["responsive", "hover"],
  display: ["responsive"],
  direction: ["responsive"],
  items: ["responsive"],
  justify: ["responsive"],
  order: ["responsive"],
  flexWrap: ["responsive"],
  flexShrink: ["responsive"],
  flexGrow: ["responsive"],
  textAlign: ["responsive"],
  position: ["responsive"],
  overflow: ["responsive"],
  transform: ["responsive"],
  rotate: ["responsive", "hover"],
  zIndex: ["responsive", "hover"],
  link: [],
  linkTarget: [],
};
