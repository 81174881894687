<template>
  <component
    @click="select"
    x-on:click="prev()"
    v-pm-segment
    v-show="!mergedProps.isHidden"
    :is="segmentTag('button')"
    class="button select-none cursor-pointer align-top inline-flex items-center justify-center"
    :class="finalClassList"
    :type="segmentTag('button') == 'button' ? type : undefined"
    v-bind="attrList"
    :style="style"
  >
    <pm-icon
      class="button__icon"
      v-bind="childSegment('icon')"
      :locked-groups="['link']"
    />
    <pm-text
      tag="span"
      class="button__label"
      v-bind="childSegment('label')"
      :locked-groups="['link']"
    />
  </component>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-carousel-prev",
  mixins: [segment],
  props,

  computed: {

  },
};
</script>
