import props from "./../../../src-utils/propDefiner";

export default {
  ...props("size", { type: Number, default: 6 }, "pm-repeater"),
  ...props("count", Number, "pm-repeater"),
  ...props(
    "dataSet",
    {
      type: Array,
      default: () => [],
    },
    "pm-repeater"
  ),
  ...props(
    "max",
    {
      type: Number,
      default: 99,
    },
    "pm-repeater"
  ),
};
