<template>
  <div
    v-show="!mergedProps.isHidden"
    class="code"
    :class="finalClassList"
    v-bind="attrList"
    :style="style"
    @click="select"
    :key="mergedProps.name"
    v-pm-segment
    v-html="mergedProps.value"
  ></div>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-code",
  props,
  mixins: [segment],
};
</script>
