<template>
  <component
    v-show="!mergedProps.isHidden"
    class="icon"
    :class="finalClassList"
    v-bind="attrList"
    :style="style"
    @click="select"
    :key="mergedProps.name"
    v-pm-segment
    :is="segmentTag('span')"
  >
    <span
      v-if="mergedProps.name"
      class="iconify w-full h-full"
      :data-icon="mergedProps.name"
    />
  </component>
</template>

<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-icon",
  props,
  mixins: [segment],
};
</script>
