<template>
  <Module :data-uuid="uuid" :data-name="name" :name="name" ref="module" @success="success()" @error="error()" />
</template>

<script>
import { cloneDeep, pickBy, merge, startCase, unionBy, get as getObject } from "lodash-es";
import { mapGetters, mapMutations } from "vuex";
import Module from "./module/Index.vue";
import bus from "@/bus";
import get from "@/gql/module/get.gql";
import getSegmentPreset from "@/gql/segment-preset/get.gql";
import getModuleTheme from "@/gql/module-theme/get.gql";

export default {
  inheritAttrs: false,

  props: {
    name: { type: String, required: true },
    uuid: { type: String, required: true },
    segments: Array,
    theme: String,
    by: String,
    id: Number,
    status: String,
    isPreview: Boolean,
  },

  data() {
    return {
      localSegments: null,
    };
  },

  created() {
    bus.$on("module-reset", (uuid) => {
      if (uuid == this.uuid) {
        this.reset();
      }
    });

    /**
     * When props are changing the number of elements on the DOM,
     * we need to refresh the layers. e.g. pm-repeater changes the elements inside.
     * Refresh layers only after state is updated in store.
     * This method should be called after segment update mutation is done.
     */
    bus.$on("refresh-active-module-layers", () => {
      if (this.uuid == this.activeSegmentModuleUuid) {
        this.$nextTick(() => {
          this.setLayersIfStore(this.uuid);
        });
      }
    });
  },

  components: {
    Module,
  },

  provide() {
    return {
      MODULE_UUID: this.uuid,
      MODULE_NAME: this.name,
      SAVED_SEGMENTS: () => this.segments,
      /**
       * Provide Injects are not reactive hence the values are not udpated
       * in all the segments after presets/theme are loaded via API
       * So making a function is a hack to get latest values
       */
      SEGMENTS: () => this.localSegments || [],
      
    };
  },

  computed: {
    ...mapGetters({
      activeSegmentModuleUuid: "editor/activeSegmentModuleUuid",
    }),
  },

  methods: {
    ...mapMutations({
      initModule: "modules/init",
      setLayers: "modules/setLayers",
    }),

    setLayersIfStore(uuid) {
      if (!this.isPreview) {
        this.setLayers([uuid, this.generateLayers()]);
      }
    },

    reset() {
      this.success(true);
    },

    init(payload) {
      if (this.isPreview) {
        this.$nextTick(() => {
          this.$emit("ready");
        });
      } else {
        this.initModule(payload);
      }
    },

    error() {
      this.init({
        uuid: this.uuid,
        status: "error",
        segments: [],
      });
    },

    async success(reset = false) {
      this.setLayersIfStore(this.uuid);

      /**
       * Always initialize all module segments irrespective of fresh module or already saved module.
       * This is to make sure that any ref change/update in segment is reflected again in API.
       * The priority is as described:
       * - defaultSegments : Written inline in module
       * - themeSegments : Over-rides the defaultSegments with theme name
       * - presetSegments : A set of segments for particular segment available globally.
       * - savedOrGlobalSegments : Segments of modules which are saved by user as savedModule or globalModule
       * - segments : Segments which are over-ridden by user which actually applies to the template.
       */

      // NON-USER DEFAULT SEGMENTS
      const defaultSegments = this.generateDefaultSegments();
      const themeSegments = await this.fetchThemeSegments();
      const nonUserSegments = unionBy(themeSegments, defaultSegments, "name");
      const nonUserSegmentsWithPresets = await this.mergePresets(
        nonUserSegments
      );

      // When reset, ignore user specific segments.
      if (reset) {
        this.localSegments = nonUserSegmentsWithPresets;
        this.init({
          uuid: this.uuid,
          segments: this.localSegments,
          status: "success",
        });
      } else {
        // USER SEGMENTS
        const fetchSavedOrGlobalSegments =
          await this.fetchSavedOrGlobalSegments();
        const userSavedSegments = this.segments || [];

        // FINAL SEGMENTS
        const finalSegments = unionBy(
          userSavedSegments,
          fetchSavedOrGlobalSegments,
          nonUserSegmentsWithPresets,
          "name"
        );
        this.localSegments = finalSegments;

        this.init({
          uuid: this.uuid,
          segments: this.localSegments,
          status: "success",
        });
      }
    },

    async fetchSavedOrGlobalSegments() {
      if (this.by == "user" && this.id) {
        return await this.$axios
          .get(`/v1/modules/${this.id}`)
          .then(({ data }) => {
            return data.themes.default.segments;
          });
      } else {
        return [];
      }
    },

    async fetchThemeSegments() {
      if (this.theme && this.theme != "default") {
        return await this.$axios
          .get(`/v1/admin/modules-by-name/${this.name}/themes/${this.theme}`)
          .then((res) => res.data);
      } else {
        return [];
      }
    },

    async mergePresets(segments) {
      const presetsRequests = segments.map((segment) => {
        if (segment.preset) {
          return this.$axios
            .get(`/v1/segment/${segment.tag}/preset/${segment.preset}`)
            .then(({ data }) => data.data);
        } else {
          return null;
        }
      });

      if (presetsRequests.length <= 0) {
        return segments;
      }

      try {
        const presets = await Promise.all(presetsRequests);
        return segments.map((segment, index) => {
          segment.props = merge(presets[index], segment.props);
          return segment;
        });
      } catch (err) {
        this.$sentry.catch(err);
        console.warn("Failed to load presets:", err);
      }
      return segments;
    },

    /**
     * Generates the default set of segments and its props
     * It is required at module initialization to find all the segments
     * and its default props.
     *
     * Once module is initialized all the props will be saved and later on
     * all the details will be loaded from API only.
     *
     * Saving on init also makes sure that even we change segment props later
     * It'll not be affected to modules which are already added by users.
     * @param {Array} nodes List of Vue Nodes
     * @param {Object} segments Segments
     */
    generateDefaultSegments(
      nodes = this.$refs.module.getRef().$children,
      segments = []
    ) {

      nodes.forEach((node) => {
        /**
         *  If a segment is give a `ref` it is editable
         */
        const name = node.$vnode.data.ref;
        if (!name) {
          return;
        }

        /**
         * The component should start with pm- to consider it as pagemaker segment.
         * Other refs should be ignored
         */
        const tag = node.$options.name;
        if (!tag || !tag.startsWith("pm-")) {
          return;
        }

        /**
         * Get props from node. Also filter out undefined props.
         */
        const props = pickBy(node.$props, (value) => value !== undefined); //Common props
        const propsByIndex = cloneDeep(props.propsByIndex);

        /**
         * propsByIndex is defined as props but when loaded inside editor, it is top level property.
         * Hence need to remove it from props and shift to top level key.
         */
        delete props.propsByIndex; // Cleanup propsByIndex from props


        const { refIndex, preset } = node;

        /**
         * When any segment is used in a for-loop
         * it will have a multiple occurence hence checking it already added in array
         */
        const existing = segments.find((segment) => segment.name == name);
        let target;

        if (existing) {
          target = existing;
        } // Not an existing segment, so create a new one
        else {
          const data = {
            tag,
            name,
            preset,
          };
          const pushedAt = segments.push(data);
          target = segments[pushedAt - 1];
        }

        /**
         * When no refIndex is provided, we only need props
         */
        if (refIndex == null) {
          target.props = cloneDeep(props);
        } else {
          /**
           * When refIndex is provided, there will be props and propsByIndex both.
           * The props are common across all the instances of segment when looped.
           * So props are required to be set only at first index
           */
          if (refIndex == 0) {
            target.props = cloneDeep(props);
            delete target.props.refIndex;
          }

          if (propsByIndex) {
            /**
             * Create a propsByIndex array if not already created.
             */
            if (!target.propsByIndex) {
              target.propsByIndex = [];
            }

            /**
             * We can also define props specific to index after it is used in a loop.
             * Those props are defined in propsByIndex prop.
             * Hence need to check those props too.
             */
            target.propsByIndex[refIndex] = cloneDeep(propsByIndex[refIndex]);
          }
        }

        if (node.$children) {
          this.generateDefaultSegments(node.$children, segments);
        }
      });

      return segments;
    },

    generateHTML() {
      let el = this.$refs.module.$el.cloneNode(true);

      /**
       * Remove elements to ignore
       * ProseMirror-trailingBreak: Added when there is an enter to keep a space. Need to remove in final HTML as it is being handeled by segment CSS
       */
      const elementsToIgnore = el.querySelectorAll(
        ".pm--ignore, .ProseMirror-trailingBreak"
      );
      elementsToIgnore.forEach((element) => {
        element.remove();
      });

      /**
       * Remove extra wrappers of tiptap editor
       * for cleaner markup
       */
      const textNodes = el.querySelectorAll(".pm--unwrap");
      textNodes.forEach((node) => {
        node.replaceWith(node.childNodes?.[0] || "");
      });

      el.removeAttribute("data-uuid");
      el.removeAttribute("data-name");

      /**
       * Remove pagemaker specific classes
       */
      const segments = el.querySelectorAll("[data-ref]");
      segments.forEach((segment) => {
        segment.removeAttribute("data-ref");
        segment.removeAttribute("data-ref-index");
        segment.removeAttribute("data-label");
      });

      /**
       * Remove extra attributes from tiptap editor
       */
      const contentEditables = el.querySelectorAll(".ProseMirror");
      contentEditables.forEach((item) => {
        item.removeAttribute("contenteditable");
        item.removeAttribute("tabindex");
        item.removeAttribute("autocomplete");
        item.removeAttribute("aria-expanded");
        item.removeAttribute("translate");
        item.classList.add("text__wrapper");
        item.classList.remove("ProseMirror");
      });

      return el.outerHTML;
    },

    generateLayers(nodes = this.$refs.module.getRef().$children) {
      return nodes
        .filter((node) => {
          const tag = node.$options.name;
          const name = node.$vnode.data.ref;
          return name && tag.startsWith("pm-") ? node : null;
        })
        .map((node) => {
          const name = node.$vnode.data.ref;
          const childName = name?.split(">").at(-1);
          const tag = node.$options.name;
          const { refIndex } = node;

          return {
            label: startCase(childName),
            name,
            tag,
            refIndex,
            children: node.$children ? this.generateLayers(node.$children) : [],
          };
        });
    },
  },
};
</script>
