<template>
  <div class="p-12 text-center bg-red-100 border-4 rounded-lg border-white">
    <h3 class="text-red-500">
      There was an error in loading module: {{ name }}
    </h3>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
  },
};
</script>