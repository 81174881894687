<template>
  <component
    v-show="!mergedProps.isHidden"
    class="media"
    :class="scl_media"
    v-bind="attrList"
    :style="style"
    @click="select"
    v-pm-segment
    :is="segmentTag('div')"
  >
    <div class="media__wrapper" :class="scl_mediaWrapper">
      <!-- Embed || Inline SVG -->
      <div
        class="media__item media__item--embed"
        :class="scl_mediaItem"
        v-if="mergedProps.source == 'embed'"
        v-html="mergedProps.value"
      />

      <!-- Inline SVG -->
      <div
        class="media__item media__item--svg"
        :class="scl_mediaItem"
        v-else-if="mergedProps.source == 'svg'"
        v-html="mergedProps.value"
      />

      <!-- Alpine JS Gallery Src -->
      <img
        class="media__item media__item--image"
        v-else-if="mergedProps.source == 'gallery'"
        :class="scl_mediaItem"
        x-bind:src="current"
        :data-pm-variable="getVariable('value')"
      />

      <!-- Default Image -->
      <img
        v-else-if="mergedProps.value"
        class="media__item media__item--image"
        :class="scl_mediaItem"
        :src="replaceVariableValue(mergedProps.value)"
        :alt="mergedProps.alt"
        :data-pm-variable="getVariable('value')"
      />
    </div>
  </component>
</template>

<script>
import props from "./props";
import segment from "../../mixins/segment";

export default {
  name: "pm-media",
  mixins: [segment],
  props,

  computed: {
    hasRatio() {
      if (this.isPropSet("ratioW") && this.isPropSet("ratioH")) {
        return true;
      } else {
        return false;
      }
    },

    ignoreProps() {
      if (this.hasRatio) {
        return ["h"];
      } else {
        return null;
      }
    },

    scl_media() {
      //If the media has ratio, there is specific CSS which needs to be applied
      if (this.hasRatio) {
        return [`media--ratio`, ...this.finalClassList];
      } else {
        return this.finalClassList;
      }
    },

    scl_mediaWrapper() {
      if (this.hasRatio) {
        return [
          ...this.variantClassList("aspect-w", "ratioW"),
          ...this.variantClassList("aspect-h", "ratioH"),
        ];
      }
      return [];
    },

    scl_mediaItem() {
      const classes = [
        ...this.variantClassList("object", "mediaFit"),
        ...this.variantClassList("object", "mediaPosition"),
      ];

      if (!this.hasRatio) {
        classes.push(...this.cl_w, ...this.cl_h);
      }
      return classes;
    },
  },
};
</script>

<style lang="scss">

</style>
