<template>
  <component
    v-show="!mergedProps.isHidden"
    class="shopify-product-variant"
    :class="finalClassList"
    v-bind="attrList"
    :style="style"
    @click="select"
    v-pm-segment
    :is="segmentTag('div')"
    data-pm-variable="shopify_product_attributes"
  >
    <!-- TODO: THE NESTED BUTTONS ARE NOT UNIQUELY EDITABLE -->
    <pm-wrapper
      v-for="(attribute, index) in attributes"
      v-bind="childSegment(`attribute`, {}, index)"
      :key="`attribute-${attribute.name}-${index}`"
      :data-pm-variable="`shopify_product_attribute_${index}`"
    >
      <slot
        name="default"
        :attribute-attrs="
          childSegment(
            `options`,
            {
              propsByIndex: attributePropsByIndex,
              key: `attribute-options-${attribute.name}-${index}`,
            },
            index
          )
        "
        :label-attrs="
          childSegment(
            `label`,
            {
              propsByIndex: labelPropsByIndex,
              disableContentEditing: false,
              key: `attribute-label-${attribute.name}-${index}`,
            },
            index
          )
        "
      >
      </slot>
    </pm-wrapper>
  </component>
</template>

<script>
import props from "./props";
import segment from "../../mixins/segment";
import { kebabCase } from "lodash-es";

export default {
  kebabCase,
  name: "pm-shopify-product-variant",
  props,
  mixins: [segment],

  computed: {
    labelPropsByIndex() {
      return this.attributes.map((attribute, index) => {
        return {
          content: `:::shopify_product_attribute_${index}_label:::`,
        };
      });
    },

    attributePropsByIndex() {
      return this.attributes.map((attribute, index) => {
        return {
          options: `:::shopify_product_attribute_${index}_options:::`,
          name: `:::shopify_product_attribute_${index}_name:::`,
        };
      });
    },

    attributes() {
      return this.VARIABLES()["shopify_product_attributes"];
    },
  },
};
</script>
