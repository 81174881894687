<template>
  <div
    v-show="!mergedProps.isHidden"
    class="carousel"
    :class="finalClassList"
    v-bind="attrList"
    :style="style"
    @click="select"
    v-pm-segment
    :is="segmentTag('div')"
    :x-data="`carousel({
        slidesToScroll: ${mergedProps.slidesToScroll},
        autoplay: ${mergedProps.autoplay},
      })`"
    :key="`${mergedProps.slidesToScroll}--${mergedProps.autoplay}`"
  >
    <slot name="default"></slot>
  </div>
</template>
<script>
import segment from "../../mixins/segment";
import props from "./props";

export default {
  name: "pm-carousel",
  mixins: [segment],
  props,
};
</script>

<style>
/**
**/
*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}

*::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}
</style>