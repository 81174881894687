import axios from "axios";
import qs from "qs";

const config = {
  timeout: 30000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
  },
  paramsSerializer: (params) => qs.stringify(params),
};

const $axios = axios.create(config);

const init = ({ baseUrl, token, params }) => {
  $axios.defaults.baseURL = baseUrl;
  $axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  $axios.defaults.params = params;
};

export default $axios;
export { init };
