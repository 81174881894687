import props from "./../../../src-utils/propDefiner";

export default {
  ...props("productId", Number, "pm-shopify-product"),
  ...props("title", [String, Number], "pm-shopify-product"),
  ...props("bodyHtml", String, "pm-shopify-product"),
  ...props("image", Object, "pm-shopify-product"),
  ...props("images", Array, "pm-shopify-product"),
  ...props("hasVariants", Boolean, "pm-shopify-product"),
  ...props(
    "attributes",
    {
      type: Array,
    },
    "pm-shopify-product"
  ),
  ...props("price", String, "pm-shopify-product"),
  ...props("compareAtPrice", String, "pm-shopify-product"),
};
