// https://github.com/ueberdosis/tiptap/issues/1378

import { Mark } from "@tiptap/core";

export default Mark.create({
  name: "tailwind",
  addAttributes() {
    return {
      color: {
        default: "",
        renderHTML: (attributes) => {
          return {
            class: attributes.color,
          };
        },
        parseHTML: (element) => {
          return element.classList[0];
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span[class]",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["span", HTMLAttributes, 0];
  },

  addCommands() {
    return {
      setTailwindColor: (color) => (ctx) => {
        if (color) {
          return ctx.chain().setMark(this.name, {
            color,
          });
        } else {
          return ctx.chain().unsetMark(this.name);
        }
      },
    };
  },
});
