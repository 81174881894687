//Thease are top level segment props
//The prop names are the camelCase version of the TailWind Utiliy Classes.
export default [
  "m",
  "mt",
  "mr",
  "mb",
  "ml",
  "mx",
  "my",
  "p",
  "pt",
  "pr",
  "pb",
  "pl",
  "px",
  "py",
];
