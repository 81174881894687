import props from "./../../../src-utils/propDefiner";

export default {
  ...props("type", { type: String }, "pm-field"),
  ...props("name", { type: String }, "pm-field"),
  ...props("required", { type: Boolean, default: undefined }, "pm-field"),
  ...props("unique", { type: Boolean, default: undefined }, "pm-field"),
  ...props("placeholder", { type: String }, "pm-field"),
  ...props("options", { type: Array }, "pm-field"),
};
