import props from "./../../../src-utils/propDefiner";

export default {
  ...props(
    "value",
    {
      type: String,
      default: () => `
<!--
You can write down a valid HTML Markup here.

You can use Tailwind CSS classes here for styling.
To know more about Tailwind: https://tailwindcss.com/
-->
<p class="text-xl p-8">Write your code here.</p>

<!-- To add custom style -->
<style type="text/css">
  /*Your CSS here*/
</style>

<!-- To add custom javascript -->
<script type="text/javascript">
  //Your JS here
</script>

<!-- You can safely remove all of this code -->
`,
    },
    "pm-embed"
  ),
};
